import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    height: 'calc(100vh - 256px)',
  },
  rootSmall: {
    height: '160px',
  },
  rootTiny: {},
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 100%',
    height: '100%',
    justifyContent: 'center',
  },
  progress: {
    color: theme.palette.loading.primary,
  },
  progressSmall: {
    color: theme.palette.loading.secondary,
  },
});

class Loading extends Component {
  render() {
    const { classes, className, small, tiny, color, contentStyle } = this.props;
    return (
      <div
        className={clsx(
          tiny ? classes.rootTiny : small ? classes.rootSmall : classes.root,
          className
        )}
      >
        <div className={classes.content} style={contentStyle}>
          <div className={this.props.className}>
            <CircularProgress
              size={25}
              className={
                small || tiny ? classes.progressSmall : classes.progress
              }
              style={{ color: color }}
            />
          </div>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(Loading);
