import { createSelector } from '@reduxjs/toolkit';
import { selectPersonalClockedInWorkRecords } from 'store/records/selectors/records.selectors';
import {
  selectProjectIdsLatestClockedInTo,
  selectProjectsArrayWithJoinedData,
} from './projectsSelectors';

export const selectPersonalClockedInRecordsProjectIds = createSelector(
  [selectPersonalClockedInWorkRecords],
  (records) => records.map((record) => record.project.id)
);

export const selectLatestProjectsClockedInTo = createSelector(
  [
    selectProjectsArrayWithJoinedData,
    selectProjectIdsLatestClockedInTo,
    selectPersonalClockedInRecordsProjectIds,
  ],
  (projects, latestProjectIds, personalClockedInProjectIds) =>
    projects.filter(
      (project) =>
        latestProjectIds.includes(project.id) &&
        project.businessTypeEnum !== 'EMPLOYER' &&
        !personalClockedInProjectIds.includes(project.id)
    )
);
