import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUpdateResource } from '../services/setUpdateResource';

export const updateResource = createAsyncThunk(
  'expenseTracking/updateResource',
  async (data, { getState }) => {
    const {resourceId, attributes} = data;
    const updatedResource = await setUpdateResource(resourceId, attributes);
    console.log('updatedResource', updatedResource);
    return updatedResource;
  }
);
