import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadAssignmentsForProject } from './thunks/loadAssignmentsForProject';
import { loadProjectAndSubProjectAssignments } from './thunks/loadProjectAndSubProjectAssignments';
import {
  addNewChildAssignment,
  addNewTopLevelAssignment,
} from 'scenes/ProjectEdit/store/actions/project-edit.actions';
import {
  closeProjectAssignmentsView,
  modifyAssignment,
  removeAssignmentAndItsChildAssignmentsToAdd,
  removeAssignmentsOfAffectedChildProjectsAfterDeletionInParentProject,
  removeRootAssignmentToAdd,
} from './actions/assignments.actions';
import { saveProjectAssignments } from './thunks/saveProjectAssignments.thunk';
import { deleteAssignment } from './thunks/deleteAssignment.thunk';
import { deleteAllProjectAssignments } from './thunks/deleteAllProjectAssignments.thunk';
import { loadActiveAssignmentsForProject } from './thunks/loadActiveAssignmentsForProject';
import { loadAssignmentsForProjects } from './thunks/loadAssignmentsForProjects';
import { saveCreatedAndUpdatedProjectAssignments } from './thunks/saveCreatedAndUpdatedProjectAssignments.thunk';
import { toggleOpenSelectAssignmentDialog } from 'scenes/Project/store/actions/projectActions';
import { loadProjectRecordsFromToday } from 'store/records/thunks/loadProjectRecordsFromToday.thunk';
import { hotSwapRecordWithNewAssignment } from 'store/records/thunks/hotSwapRecordWithNewAssignment';

const assignmentsAdapter = createEntityAdapter();

const newRootAssignmentsAdapter = createEntityAdapter();

const newChildAssignmentsAdapter = createEntityAdapter();

export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState: {
    assignments: assignmentsAdapter.getInitialState(),
    selectedAssignment: '',
    loading: false,
    assignmentsLoaded: false,
    rootAssignmentsToAdd: newRootAssignmentsAdapter.getInitialState(),
    childAssignmentsToAdd: newChildAssignmentsAdapter.getInitialState(),
    assignmentModifications: {},
    isSelectAssignmentDialogOpen: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadAssignmentsForProject.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAssignmentsForProject.fulfilled, (state, action) => {
        assignmentsAdapter.setMany(state.assignments, action.payload);
        state.loading = false;
      })
      .addCase(loadAssignmentsForProject.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loadActiveAssignmentsForProject.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadActiveAssignmentsForProject.fulfilled, (state, action) => {
        assignmentsAdapter.setMany(state.assignments, action.payload);
        state.loading = false;
      })
      .addCase(loadActiveAssignmentsForProject.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loadProjectAndSubProjectAssignments.pending, (state) => ({
        ...state,
        loading: true,
        assignmentsLoaded: false,
      }))
      .addCase(
        loadProjectAndSubProjectAssignments.fulfilled,
        (state, action) => {
          assignmentsAdapter.setMany(state.assignments, action.payload);
          state.loading = false;
          state.assignmentsLoaded = true;
        }
      )
      .addCase(
        loadProjectAndSubProjectAssignments.rejected,
        (state, action) => ({
          ...state,
          loading: false,
          assignmentsLoaded: true,
          error: action.error,
        })
      )
      .addCase(loadAssignmentsForProjects.fulfilled, (state, action) => {
        const assignments = action.payload.filter(
          (assignment) => assignment && assignment.id
        );

        assignmentsAdapter.setMany(state.assignments, assignments);
      })
      .addCase(addNewTopLevelAssignment, (state, action) => {
        newRootAssignmentsAdapter.setOne(
          state.rootAssignmentsToAdd,
          action.payload
        );
      })
      .addCase(addNewChildAssignment, (state, action) => {
        newChildAssignmentsAdapter.setOne(
          state.childAssignmentsToAdd,
          action.payload
        );
      })
      .addCase(saveProjectAssignments.fulfilled, (state, action) => {
        assignmentsAdapter.setMany(state.assignments, action.payload);
        state.rootAssignmentsToAdd =
          newRootAssignmentsAdapter.getInitialState();
        state.childAssignmentsToAdd =
          newChildAssignmentsAdapter.getInitialState();
        state.assignmentModifications = {};
      })
      .addCase(
        saveCreatedAndUpdatedProjectAssignments.fulfilled,
        (state, action) => {
          console.log(
            'saveCreatedAndUpdatedProjectAssignments.fulfilled',
            action.payload
          );
          assignmentsAdapter.upsertMany(state.assignments, action.payload);
        }
      )
      .addCase(loadProjectRecordsFromToday.fulfilled, (state, action) => {
        assignmentsAdapter.setMany(
          state.assignments,
          action.payload.recordsAssignments
        );
      })
      .addCase(closeProjectAssignmentsView, (state, action) => {
        state.rootAssignmentsToAdd =
          newRootAssignmentsAdapter.getInitialState();
        state.childAssignmentsToAdd =
          newChildAssignmentsAdapter.getInitialState();
        state.assignmentModifications = {};
      })
      .addCase(modifyAssignment, (state, action) => {
        const assignmentValues = action.payload;

        state.assignmentModifications[assignmentValues.id] = {
          ...state.assignmentModifications[assignmentValues.id],
          ...assignmentValues,
        };
      })
      .addCase(deleteAssignment.fulfilled, (state, action) => {
        assignmentsAdapter.setMany(state.assignments, action.payload);
      })
      .addCase(deleteAllProjectAssignments.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(deleteAllProjectAssignments.fulfilled, (state, action) => {
        assignmentsAdapter.setMany(state.assignments, action.payload);
        state.rootAssignmentsToAdd =
          newRootAssignmentsAdapter.getInitialState();
        state.childAssignmentsToAdd =
          newChildAssignmentsAdapter.getInitialState();
        state.assignmentModifications = {};
        state.loading = false;
      })
      .addCase(
        removeAssignmentsOfAffectedChildProjectsAfterDeletionInParentProject,
        (state, action) => {
          assignmentsAdapter.removeMany(state.assignments, action.payload);
        }
      )
      .addCase(deleteAllProjectAssignments.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(removeRootAssignmentToAdd, (state, action) => {
        newRootAssignmentsAdapter.removeOne(
          state.rootAssignmentsToAdd,
          action.payload
        );
      })
      .addCase(removeAssignmentAndItsChildAssignmentsToAdd, (state, action) => {
        newChildAssignmentsAdapter.removeMany(
          state.childAssignmentsToAdd,
          action.payload
        );
        action.payload.forEach((assignmentId) => {
          delete state.assignmentModifications[assignmentId];
        });
      })
      .addCase(toggleOpenSelectAssignmentDialog, (state, action) => {
        state.isSelectAssignmentDialogOpen = action.payload;
      })
      .addCase(hotSwapRecordWithNewAssignment.fulfilled, (state, action) => {
        state.isSelectAssignmentDialogOpen = false;
      });
  },
});
