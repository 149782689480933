import { createSelector } from '@reduxjs/toolkit';
import { groupBy, orderBy, uniqBy } from 'lodash-es';
import {
  selectProjectsArray,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import { selectSelectedProjectResources } from 'store/resources/selectors/resources.selectors';

const selectDefectListState = (state) => state.defectList;

export const selectDefectListsEntities = createSelector(
  [selectDefectListState],
  (state) => state.defectLists.entities
);

export const selectDefectItemsEntities = createSelector(
  [selectDefectListState],
  (state) => state.defectItems.entities
);

export const selectDefectImagesEntities = createSelector(
  [selectDefectListState],
  (state) => state.defectImages.entities
);

export const selectDefectImagesArray = createSelector(
  [selectDefectImagesEntities],
  (entities) => Object.values(entities)
);

export const selectDefectListsArray = createSelector(
  [selectDefectListsEntities],
  (entities) => Object.values(entities)
);

export const selectDefectItemsArray = createSelector(
  [selectDefectItemsEntities],
  (entities) => Object.values(entities)
);

export const selectSelectedProjectDefectLists = createSelector(
  [selectDefectListsArray, selectSelectedProjectId],
  (defectLists, selectedProjectId) =>
    defectLists.filter(
      (defectList) => defectList.projectId === selectedProjectId
    )
);

export const selectSelectedProjectDefectItems = createSelector(
  [selectDefectItemsArray, selectSelectedProjectId],
  (defectItems, selectedProjectId) =>
    defectItems.filter(
      (defectItem) => defectItem.projectId === selectedProjectId
    )
);

export const selectUsersByRecordStatusByCompany = createSelector(
  (state) => state.defectList.users,
  (users) => {
    console.log('🚀 ~ users', users);
    return users;
  }
);

export const selectDefectItemsToCreate = createSelector(
  [selectDefectListState],
  (state) => state.defectItemsToCreate
);

export const selectDefectAssignmentsEntities = createSelector(
  [selectDefectListState],
  (state) => state.defectAssignments.entities
);

export const selectDefectAssignments = createSelector(
  [selectDefectAssignmentsEntities],
  (defectAssignmentEntities) => Object.values(defectAssignmentEntities)
);

export const selectDefectAssignmentModifications = createSelector(
  [selectDefectListState],
  (state) => state.defectAssignmentModifications
);

export const selectDefectImagesToAdd = createSelector(
  [selectDefectListState],
  (state) => state.defectImagesToAdd
);

export const selectUsersCombined = createSelector(
  selectUsersByRecordStatusByCompany,
  (users) => {
    let combinedObject = {};
    Object.keys(users).forEach((key) => {
      Object.keys(users[key]).map((company) => {
        combinedObject = { ...combinedObject, [company]: users[key][company] };
      });
    });
    return combinedObject;
  }
);

export const selectIsDefectListsDataLoading = createSelector(
  [selectDefectListState],
  (state) => state.loading
);

export const selectIsCreateDefectListDialogOpen = createSelector(
  [selectDefectListState],
  (state) => state.isCreateDefectListDialogOpen
);

export const selectSelectedDefectListId = createSelector(
  [selectDefectListState],
  (state) => state.selectedDefectListId
);

export const selectIsEditingListAttributes = createSelector(
  [selectDefectListState],
  (state) => state.isEditingListAttributes
);

export const selectDefectListAttributeModifications = createSelector(
  [selectDefectListState],
  (state) => state.defectListAttributeModifications
);

export const selectIsAddNewDefectsDialogOpen = createSelector(
  [selectDefectListState],
  (state) => state.isAddNewDefectsDialogOpen
);

export const selectIsCreatingDefectList = createSelector(
  [selectDefectListState],
  (state) => state.isCreatingDefectList
);

export const selectIsCreatingDefectItems = createSelector(
  [selectDefectListState],
  (state) => state.isCreatingDefectItems
);

export const selectSelectedDefectItemIdToDelete = createSelector(
  [selectDefectListState],
  (state) => state.selectedDefectItemIdToDelete
);

export const selectSelectedDefectItemIdToEdit = createSelector(
  [selectDefectListState],
  (state) => state.selectedDefectItemIdToEdit
);

export const selectDefectEditAssignmentsToAdd = createSelector(
  [selectDefectListState],
  (state) => state.defectEditAssignmentsToAdd
);

export const selectDefectEditRemovedAssignations = createSelector(
  [selectDefectListState],
  (state) => state.defectEditRemovedAssignations
);

export const selectEditedDefectAttributes = createSelector(
  [selectDefectListState],
  (state) => state.editedDefectAttributes
);

export const selectDefectEditImagesToDelete = createSelector(
  [selectDefectListState],
  (state) => state.defectEditImagesToDelete
);

export const selectIsUpdatingDefect = createSelector(
  [selectDefectListState],
  (state) => state.isUpdatingDefectItem
);

export const selectSelectedDefectListData = createSelector(
  [
    selectDefectListsEntities,
    selectDefectItemsArray,
    selectDefectAssignments,
    selectSelectedDefectListId,
    selectDefectListAttributeModifications,
  ],
  (
    defectListEntities,
    defectItems,
    defectAssignments,
    selectedDefectListId,
    defectListModifications
  ) => {
    const defectList = defectListEntities[selectedDefectListId];

    const defectListWithModifications = {
      ...defectList,
      ...defectListModifications,
    };

    const isDefectListAttributesModified =
      Object.keys(defectListModifications).length > 0;

    const listDefectItems = defectItems.filter(
      (defectItem) => defectItem.defectListId === selectedDefectListId
    );

    return {
      defectList: isDefectListAttributesModified
        ? defectListWithModifications
        : defectListEntities[selectedDefectListId],
      defectItems: orderBy(listDefectItems, 'createdAt', 'desc'),
      defectAssignments: defectAssignments.filter(
        (defectAssignment) =>
          defectAssignment.defectListId === selectedDefectListId
      ),
    };
  }
);

export const selectDefectsSummaryData = createSelector(
  [selectSelectedProjectDefectLists, selectSelectedProjectDefectItems],
  (defectLists, defectItems) => {
    const defectListsAmount = defectLists.length;
    const defectItemsAmount = defectItems.length;

    return { defectListsAmount, defectItemsAmount };
  }
);

export const selectDefectsAssigneeEmployerAndEmployeeOptions = createSelector(
  [selectSelectedProjectResources, selectProjectsArray, (_, args) => args],
  (resources, projects, assigneeSearchText) => {
    // const searchFilteredResources = resources.filter(
    //   (resource) =>
    //     resource.firstName.toLowerCase().includes(assigneeSearchText) ||
    //     resource.lastName.toLowerCase().includes(assigneeSearchText)
    // );

    // const effectiveResources = assigneeSearchText
    //   ? searchFilteredResources
    //   : resources;

    const employerBusinessIds = uniqBy(
      resources.map((resource) => resource.employerBusinessId)
    );

    let employerProjects = [];

    employerBusinessIds.forEach((businessId) => {
      const foundEmployer = projects.find(
        (project) =>
          project.businessTypeEnum === 'EMPLOYER' &&
          project.business_id === businessId
      );

      if (foundEmployer) {
        if (
          (assigneeSearchText &&
            foundEmployer.name.toLowerCase().includes(assigneeSearchText)) ||
          !assigneeSearchText
        ) {
          employerProjects.push(foundEmployer);
        }
      }
    });

    const uniqueUserResourcesOfEmployer = uniqBy(resources, (resource) =>
      [resource.user, resource.employer, resource.project].join()
    );

    const employeeResourcesGroupedByBusinessId = groupBy(
      uniqueUserResourcesOfEmployer,
      'employerBusinessId'
    );

    return {
      employerOptions: employerProjects,
      employeeOptions: employeeResourcesGroupedByBusinessId,
    };
  }
);

export const selectDefectItemAssignmentsDataByDefectId = createSelector(
  [selectDefectAssignments, (_, args) => args],
  (defectAssignments, args) => {
    const thisDefectItemAssignments = defectAssignments.filter(
      (assignment) => assignment.defectId === args
    );

    let defectAssignedBusinesses = [];
    let defectUserAssignments = [];

    thisDefectItemAssignments.forEach((defectAssignment) => {
      if (defectAssignment.userId) {
        defectUserAssignments.push(defectAssignment);
      } else {
        defectAssignedBusinesses.push(defectAssignment);
      }
    });

    return {
      assignmentBusinesses: defectAssignedBusinesses,
      defectUserAssignments,
    };
  }
);

export const selectSelectedDefectItemToEdit = createSelector(
  [selectDefectItemsEntities, selectSelectedDefectItemIdToEdit],
  (defectItems, selectedDefectId) => defectItems[selectedDefectId]
);

export const selectEditDefectExistingAssignments = createSelector(
  [
    selectDefectAssignments,
    selectSelectedDefectItemIdToEdit,
    selectDefectEditAssignmentsToAdd,
    selectDefectEditRemovedAssignations,
  ],
  (
    defectAssignments,
    selectedDefectId,
    addedAssignments,
    removedAssignments
  ) => {
    const selectedDefectAssignments = defectAssignments.filter(
      (assignment) => assignment.defectId === selectedDefectId
    );

    const selectedDefectAddedAssignmentsArray = Object.values(addedAssignments);

    let removedBusinessAssignments = [];
    let removedUserAssignments = [];

    Object.values(removedAssignments).forEach((removedAssignment) => {
      if (!removedAssignment.userId) {
        removedBusinessAssignments.push(removedAssignment.businessId);
      } else {
        removedUserAssignments.push(removedAssignment.userId);
      }
    });
    const filteredExistingAssignments = selectedDefectAssignments.filter(
      (defectAssignment) => {
        const isFoundInRemovedBusinesses = removedBusinessAssignments.includes(
          defectAssignment.businessId
        );

        const isFoundInRemovedUserAssignments = removedUserAssignments.includes(
          defectAssignment.userId
        );
        if (isFoundInRemovedBusinesses || isFoundInRemovedUserAssignments) {
          return false;
        } else {
          return true;
        }
      }
    );

    return [
      ...filteredExistingAssignments,
      ...selectedDefectAddedAssignmentsArray,
    ];
  }
);

export const selectSelectedProjectDefectImages = createSelector(
  [selectDefectImagesArray, selectSelectedProjectId],
  (defectImages, selectedProjectId) =>
    defectImages.filter(
      (defectImage) => defectImage.projectId === selectedProjectId
    )
);

export const selectDefectImagesByDefectId = createSelector(
  [selectSelectedProjectDefectImages, (_, args) => args],
  (defectImages, args) =>
    defectImages.filter((defectImage) => defectImage.defectId === args)
);

export const selectSelectedDefectItemImages = createSelector(
  [
    selectDefectImagesArray,
    selectSelectedDefectItemIdToEdit,
    selectDefectEditImagesToDelete,
  ],
  (defectImages, selectedDefectId, imagesAddedForDeletion) =>
    defectImages.filter(
      (defectImage) =>
        defectImage.defectId === selectedDefectId &&
        !imagesAddedForDeletion.includes(defectImage.id)
    )
);
