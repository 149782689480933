import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { selectPersonalClockedInWorkRecords } from '../selectors/records.selectors';
import patchHotSwapRecordWithNewAssignment from '../services/patchHotSwapRecordWithNewAssignment';
import { selectUserCoordinates } from 'store/user/selectors.js/user.selectors';

export const hotSwapRecordWithNewAssignment = createAsyncThunk(
  'records/hotSwapRecordWithNewAssignment',
  async (recordSwapData, { getState }) => {
    const { assignmentId, recordClockOutAttributes } = recordSwapData;

    const personalRecords = selectPersonalClockedInWorkRecords(getState());

    const selectedProjectId = selectSelectedProjectId(getState());

    const coordinates = selectUserCoordinates(getState());

    const filledRecordClockOutAttributes = {
      ...recordClockOutAttributes,
      latOut: coordinates ? coordinates.latitude : undefined,
      longOut: coordinates ? coordinates.longitude : undefined,
    };

    const clockedInProjectRecord = personalRecords.find(
      (record) => record.project.id === selectedProjectId
    );

    const updatedRecord = await patchHotSwapRecordWithNewAssignment(
      clockedInProjectRecord.id,
      assignmentId,
      filledRecordClockOutAttributes
    );

    return updatedRecord;
  }
);
