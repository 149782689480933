/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import Config from 'Config';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from 'components/DialogWrapper/DialogWrapper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MapIcon from 'mdi-material-ui/MapMarker';
import Button from 'components/Button/Button';
import DialogHeader from 'components/DialogHeader/DialogHeader';
import Cropper from 'components/ImageCrop/Cropper';
import uuidv4 from 'uuid/v4';
import toBase64 from 'utils/toBase64';
import readAndCompress from 'utils/readAndCompress';
import getCredentials from 'utils/getCredentials';
import aws4 from 'aws4';
import filePermissionCreate from 'scenes/Project/api/filePermissionCreate';
import { withApollo } from 'react-apollo';
import { useAlert } from 'react-alert';
import useStyles from './LocationMustStyles';

import Icon from '@mdi/react';
import { mdiMapMarkerOff } from '@mdi/js';
import { ConsoleNetwork } from 'mdi-material-ui';
import { selectLocationMustDialog } from 'store/records/selectors/records.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { clockInRecord } from 'store/records/thunks/clockInRecord.thunk';
import { closeLocationMustDialog } from 'store/records/actions/records.actions';

const styles = (theme) => ({
  dialogPaper: {
    background: theme.palette.primary.main,
    position: 'initial',
    overflow: 'initial',
  },
  editHeader: {
    background: theme.palette.primary.gradient,
  },
  dialogContent: {
    paddingTop: 8,
    paddingBottom: 32,
    [theme.breakpoints.down('xs')]: {
      padding: '0 8px 8px 8px',
    },
  },
  dialogActions: {
    [theme.breakpoints.down('xs')]: {
      margin: 4,
    },
  },
  gridContainer: {},
  gridItem: {
    padding: '0 8px',
  },
  gridImage: {
    marginTop: '25px',
    textAlign: 'center',
  },
  select: {
    color: theme.palette.primary.main,
  },
  image: {
    width: '100%',
    maxWidth: '120px',
    marginLeft: '16px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '80px',
    },
  },
  button: {
    display: 'block',
    width: '300px',
    marginTop: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loading: {
    ...theme.component.dashboardButtonIcon,
  },
});

/**
 * LocationMust is a dialog which opens when location is mandatory, but isn't enabled by the user
 * @param {project, onClose, open} props
 */
function LocationMust({ project, open }) {
  const dispatch = useDispatch();

  const { t } = useTranslation('common');
  const classes = useStyles();
  const [bypassDisabled, setBypassDisabled] = useState(true);
  const [bypassText, setBypassText] = useState(3);
  const countdownRef = useRef(null);

  function bypassLocation() {
    setBypassDisabled(true);
    setBypassText(3);
    countdownRef.current = setInterval(() => {
      if (bypassText > 1) {
        setBypassText((prev) => prev - 1);
      }
    }, 1000);
  }

  const onClose = () => {
    dispatch(closeLocationMustDialog());
  };

  useEffect(() => {
    if (bypassText < 1) {
      clearInterval(countdownRef.current);
      setBypassText('Leimaa silti');
      setBypassDisabled(false);
    }
  }, [bypassText]);

  useEffect(() => {
    if (bypassDisabled === true) {
      bypassLocation();
    }

    return () => {
      clearInterval(countdownRef.current);
    };
  }, []);

  /**
   * browserDetect detects your browser and returns it as a String
   */
  function browserDetect() {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'Google Chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'Firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'Safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'Opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'Edge';
    } else {
      browserName = '?';
    }

    return browserName;
  }

  const clockInWithOutLocation = () => {
    dispatch(
      clockInRecord({
        projectId: project.id,
        allowClockInWithOutLocation: true,
      })
    );
    dispatch(closeLocationMustDialog());
  };

  return (
    <Dialog
      disableBackdropClick
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={onClose}
      aria-labelledby="contact-edit-dialog"
      maxWidth="sm"
      fullWidth
    >
      <DialogHeader
        icon={<Icon path={mdiMapMarkerOff} size={1} className={classes.icon} />}
        title={t('location.location_must')}
        onClose={onClose}
      />
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={12} container justifyContent="flex-start">
            <Typography>
              {t('location.location_must_explanation1')}
              <span style={{ fontStyle: 'italic' }}>
                {' '}
                {project.projectKey} - {project.name}{' '}
              </span>
              {t('location.location_must_explanation2')}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-start">
            <Typography>{t('location.location_must_explanation4')}</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-start">
            <Typography>
              {t('location.browser') + ` ${browserDetect()} ` + '- '}
              <a
                href="https://support.google.com/chrome/answer/142065?hl=fi&co=GENIE.Platform%3DDesktop&oco=1"
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                {t('location.guide')}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-start">
            <br />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-start">
            {project.contact_phone && (
              <Typography>{`${t('location.location_must_explanation3')} ${
                project.contact_first_name || ''
              } ${project.contact_last_name || ''} ${
                project.contact_phone
              }`}</Typography>
            )}
            {!project.contact_phone && (
              <Typography>
                {t('location.location_must_explanation3_alternative')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <Divider />

      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={clockInWithOutLocation}
          className={classes.actionBtn}
          style={{ minWidth: '100px' }}
          color="danger"
          disabled={bypassDisabled}
        >
          {bypassText}
        </Button>
        <Button
          onClick={onClose}
          className={classes.actionBtn}
          outline
          color="primary"
        >
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(LocationMust);
