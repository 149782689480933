import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { postGenerateProjectEmployeeReport } from '../services/getProjectEmployeeReport.service';

export const generateProjectEmployeeReport = createAsyncThunk(
  'project/generateEmployeeReport',
  async (dialogSettings, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const utcOffset = moment.parseZone(moment().format()).format('Z')

    const {
      language,
      filetype,
      selectedReportType,
      reportDateRange,
      reportFromDate,
      reportToDate,
    } = dialogSettings;

    const generationParams = {
      language,
      filetype,
      utcOffset,
      selectedReportType,
      reportDateRange,
      reportFromDate,
      reportToDate,
    };

    const report = await postGenerateProjectEmployeeReport(
      selectedProjectId,
      generationParams
    );

    return report;
  }
);
