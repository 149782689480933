import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import {
  selectDefectItemsToCreate,
  selectDefectListAttributeModifications,
  selectSelectedDefectListId,
} from '../selectors/defect-list.selectors';
import { patchUpdateDefectListData } from 'scenes/DefectList/services/patch-update-defect-list-data.service';

export const saveDefectListChanges = createAsyncThunk(
  'defectLists/saveDefectListChanges',
  async (_, { getState }) => {
    const selectedDefectListId = selectSelectedDefectListId(getState());

    const selectedProjectId = selectSelectedProjectId(getState());

    const defectListAttributeModifications =
      selectDefectListAttributeModifications(getState());

    const defectItemsToCreate = selectDefectItemsToCreate(getState());

    const updatedDefectListData = await patchUpdateDefectListData(
      selectedProjectId,
      selectedDefectListId,
      defectListAttributeModifications,
      defectItemsToCreate
    );

    return updatedDefectListData;
  }
);
