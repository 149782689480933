import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectDefectListsData } from 'scenes/DefectList/services/get-project-defect-lists-data.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadProjectDefectListsData = createAsyncThunk(
  'defectLists/loadProjectDefectListsData',
  async (_, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());
    const defectListsData = await getProjectDefectListsData(selectedProjectId);

    return defectListsData;
  }
);
