import React, { useMemo } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextInput from 'components/TextInput/TextInput';
import { CircularProgress } from '@material-ui/core';

const ComboBox = (props) => {
  const {
    items,
    selected,
    onChange,
    label,
    index,
    emptyOption,
    emptyAsNull,
    disabled = false,
    loading = false,
    emptyOptionNullText = false,
  } = props;
  let options = emptyOption
    ? [
        {
          key: 'Ei määritetty',
          name: 'Ei määritetty',
          value: emptyAsNull ? null : 'none',
        },
      ]
    : [];
  if (items) {
    options = options.concat(
      items.map((item) => ({
        key: item.name,
        name: item.projectKey ? item.name + ' - ' + item.projectKey : item.name,
        value: item.id,
        number: item.number,
      }))
    );
  }

  const value = useMemo(() => {
    if (items && selected) {
      return items.filter((item) => item.id === selected)[0];
    } else {
      if (emptyOptionNullText) {
        return null;
      } else {
        return {
          key: 'Ei määritetty',
          name: 'Ei määritetty',
          value: emptyAsNull ? null : 'none',
        };
      }
    }
  }, [selected, items, emptyOptionNullText, emptyAsNull, options]);

  return (
    <Autocomplete
      options={options}
      autoHighlight
      getOptionLabel={(option) =>
        option.number
          ? option.number + '. ' + option.name
          : option.projectKey
          ? option.name + ' - ' + option.projectKey
          : option.name || ''
      }
      value={value}
      disableClearable
      openOnFocus
      blurOnSelect
      loading={loading}
      onChange={(event, newValue) => onChange(newValue.value, index)}
      disabled={disabled}
      renderInput={(params) => (
        <TextInput
          {...params}
          label={label}
          fullWidth
          //displayEmpty
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',

            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

ComboBox.defaultProps = {
  items: [],
  emptyOption: true,
};

export default ComboBox;
