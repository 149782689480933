import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { selectSelectedDefectItemIdToDelete } from '../selectors/defect-list.selectors';
import { patchUpdateDefectItemAsDeleted } from 'scenes/DefectList/services/patch-update-defect-item-as-deleted.service copy';

export const deleteDefectItem = createAsyncThunk(
  'defectLists/deleteDefectItem',
  async (_, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const selectedDefectItemIdToDelete = selectSelectedDefectItemIdToDelete(
      getState()
    );

    const updatedDefectItem = await patchUpdateDefectItemAsDeleted(
      selectedProjectId,
      selectedDefectItemIdToDelete
    );

    return updatedDefectItem;
  }
);
