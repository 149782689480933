import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getProjectForms from 'scenes/ProjectForms/utils/getProjectForms';

export const loadInitialFormsData = createAsyncThunk(
  'projectForm/loadInitialFormsData',
  async (projectId, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    let projectForms = [];

    let response;

    // Get all forms for the project using lastEvaluatedKey
    do {
      response = await getProjectForms(projectId, response?.LastEvaluatedKey, params);
      projectForms.push(...response.Items);
    } while (response.LastEvaluatedKey);

    const forms = [].concat(...projectForms);

    return {
      forms,
    };
  }
);
