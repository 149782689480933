import { Grid, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/Button/Button';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import TextInput from 'components/TextInput/TextInput';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import { closeAddNewUserDialog } from 'store/projectUsers/actions/project-users.actions';
import { createOrUpdateUser } from 'store/user/thunks/createOrUpdateUser.thunk';

export const AddUserDialog = () => {
  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      ...theme.component.dialogPaper,
      [theme.breakpoints.down('xs')]: {
        ...theme.component.dialogPaperFullscreen,
      },
    },
    dialogContent: {
      paddingTop: theme.spacing(2),
    },
    dialogActions: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  }));

  const dispatch = useDispatch();

  const classes = useStyles();

  const { t } = useTranslation('common');

  const selectedProject = useSelector(selectSelectedProject);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [taxNumber, setTaxNumber] = useState('');

  const [projectKey, setProjectKey] = useState(
    selectedProject?.projectKey || ''
  );
  const [businessId, setBusinessId] = useState('');

  const onConfirm = () => {
    let phoneNumberToAdd = phoneNumber;
    if (phoneNumberToAdd.charAt(0) !== '+') {
      phoneNumberToAdd = '+' + phoneNumber;
    }
    dispatch(
      createOrUpdateUser({
        userAttributes: {
          firstName,
          lastName,
          phoneNumber: phoneNumberToAdd,
          ...(dateOfBirth && {
            dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
          }),
          ...(taxNumber && { taxNumber: taxNumber }),
        },
        projectKeyOfProjectToJoin: projectKey,
        businessIdOfEmployerToJoinWith: businessId,
      })
    );
  };

  const close = () => {
    dispatch(closeAddNewUserDialog());
  };

  return (
    <Dialog
      open={true}
      onClose={() => close()}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>{t('add-user-dialog.add-new-user')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextInput
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
              label={t('add-user-dialog.first-name')}
              type="text"
              id="firstNameInput"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
              label={t('add-user-dialog.last-name')}
              type="text"
              id="lastNameInput"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              onChange={(value) => setPhoneNumber(value)}
              label={t('add-user-dialog.phone-number')}
              value={phoneNumber}
              type="phone"
              id="phoneNumberInput"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <DateTimePicker
              type="date"
              onChange={(value) => setDateOfBirth(value)}
              value={dateOfBirth}
              viewMode="days"
              timeFormat={false}
              inputProps={{
                label: t('add-user-dialog.date-of-birth'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              onChange={(event) => setTaxNumber(event.target.value)}
              value={taxNumber}
              label={t('add-user-dialog.tax-number')}
              type="text"
              id="taxNumberInput"
              fullWidth
            />
          </Grid>
          <h2>{t('add-user-dialog.project-to-add-user-into')}</h2>
          <Grid item xs={12}>
            <TextInput
              value={projectKey}
              onChange={(event) => setProjectKey(event.target.value)}
              label={t('add-user-dialog.project-key-of-project-to-join')}
              type="number"
              id="projectKeyInput"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              value={businessId}
              onChange={(event) => setBusinessId(event.target.value)}
              label={t('add-user-dialog.business-id-of-employer-to-join-with')}
              type="text"
              id="projectKeyInput"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>{t('common.cancel')}</Button>
        <Button
          disabled={!firstName || !lastName || !phoneNumber || !projectKey}
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => onConfirm()}
        >
          {t('add-user-dialog.add-user')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
