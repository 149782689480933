import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getAllProjectsUsersByCompany from '../../utils/getAllProjectsUsersByCompany';

/**
 * Load all projects users by company
 */
export const loadAllProjectsUsersByCompany = createAsyncThunk(
    'defectList/loadAllProjectsUsersByCompany',
    async (projectId, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        let getUsersPromises = [];

        getUsersPromises.push(getAllProjectsUsersByCompany(projectId, params));

        const getUsersPromisesSettled = await Promise.allSettled(
            getUsersPromises
        ).then((promises) => promises.map((promise) => promise.value));

        const usersByCompany = getUsersPromisesSettled[0];

        return {
            usersByCompany,
        };
    }
);