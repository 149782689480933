import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as defectListActions from './actions/defectListActions';
import { loadProjectDefectListsData } from './thunks/loadProjectDefectListsData.thunk';
import uuidv4 from 'uuid/v4';
import { createDefectList } from './thunks/createDefectList.thunk';
import { loadAllProjectsUsersByCompany } from './thunks/loadAllProjectsUsersByCompany';
import { enqueueSnackbar } from 'notistack';
import { saveDefectListChanges } from './thunks/saveDefectListChanges.thunk';
import { createNewDefects } from './thunks/createNewDefects.thunk';
import { deleteDefectItem } from './thunks/deleteDefectItem.thunk';
import { updateDefectItem } from './thunks/updateDefectItem.thunk';

const defectListsAdapter = createEntityAdapter();
const defectItemsAdapter = createEntityAdapter();
const defectAssignmentsAdapter = createEntityAdapter({
  selectId: (defectAssignment) =>
    `${defectAssignment.defectId}#${defectAssignment.assigneeId}`,
});
const defectImagesAdapter = createEntityAdapter();

const defectListSlice = createSlice({
  name: 'defectList',
  initialState: {
    defectLists: defectListsAdapter.getInitialState(),
    defectItems: defectItemsAdapter.getInitialState(),
    defectAssignments: defectAssignmentsAdapter.getInitialState(),
    defectImages: defectImagesAdapter.getInitialState(),
    isCreateDefectListDialogOpen: false,
    isAddNewDefectsDialogOpen: false,
    isEditingListAttributes: false,
    loading: false,
    isCreatingDefectList: false,
    isCreatingDefectItems: false,
    defectListAttributeModifications: {},
    defectItemsToCreate: {},
    editedDefectAttributes: {},
    defectImagesToAdd: [],
    defectAssignmentModifications: {},
    defectEditAssignmentsToAdd: {},
    defectEditRemovedAssignations: {},
    defectEditImagesToDelete: [],
    selectedDefectListId: '',
    isConfirmDefectDeleteDialogOpen: false,
    selectedDefectItemIdToDelete: '',
    selectedDefectItemIdToEdit: '',
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadProjectDefectListsData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadProjectDefectListsData.fulfilled, (state, action) => {
        const { defectLists, defectItems, defectAssignments, defectImages } =
          action.payload;
        defectListsAdapter.setMany(state.defectLists, defectLists);
        defectItemsAdapter.setMany(state.defectItems, defectItems);
        defectAssignmentsAdapter.setMany(
          state.defectAssignments,
          defectAssignments
        );
        defectImagesAdapter.setMany(state.defectImages, defectImages);
        state.loading = false;
      })
      .addCase(defectListActions.addDefectItemToCreate, (state, action) => {
        const newInitialDefectItem = { id: uuidv4() };

        state.defectItemsToCreate[newInitialDefectItem.id] =
          newInitialDefectItem;
      })
      .addCase(createDefectList.pending, (state, action) => {
        state.isCreatingDefectList = true;
      })
      .addCase(createDefectList.fulfilled, (state, action) => {
        const { defectList, defectItems, defectAssignments, defectImages } =
          action.payload;
        defectListsAdapter.setOne(state.defectLists, defectList);
        defectItemsAdapter.setMany(state.defectItems, defectItems);
        defectAssignmentsAdapter.setMany(
          state.defectAssignments,
          defectAssignments
        );
        defectImagesAdapter.setMany(state.defectImages, defectImages);

        state.defectAssignmentModifications = {};
        state.defectItemsToCreate = {};
        state.defectImagesToAdd = [];

        state.isCreateDefectListDialogOpen = false;
        state.isCreatingDefectList = false;
        enqueueSnackbar('ViPu-lista luotu onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(createDefectList.rejected, (state, action) => {
        state.isCreatingDefectList = false;
        enqueueSnackbar('Virhe ViPu-listan luonnissa', {
          variant: 'error',
        });
      })
      .addCase(createNewDefects.pending, (state, action) => {
        state.isCreatingDefectItems = true;
      })
      .addCase(createNewDefects.fulfilled, (state, action) => {
        const { defectItems, defectAssignments, addedDefectImages } =
          action.payload;
        defectItemsAdapter.setMany(state.defectItems, defectItems);
        defectAssignmentsAdapter.setMany(
          state.defectAssignments,
          defectAssignments
        );

        defectImagesAdapter.setMany(state.defectImages, addedDefectImages);

        state.isCreatingDefectItems = false;

        enqueueSnackbar('ViPu-lista päivitetty', {
          variant: 'success',
        });

        state.defectImagesToAdd = [];
      })
      .addCase(createNewDefects.rejected, (state, action) => {
        state.isCreatingDefectItems = false;
      })
      .addCase(
        defectListActions.openCreateDefectListDialog,
        (state, action) => {
          const id = uuidv4();
          state.isCreateDefectListDialogOpen = true;
          state.defectItemsToCreate[id] = { id };
        }
      )
      .addCase(
        defectListActions.closeCreateDefectListDialog,
        (state, action) => {
          state.isCreateDefectListDialogOpen = false;
          state.defectItemsToCreate = {};
          state.defectAssignmentModifications = {};
        }
      )
      .addCase(defectListActions.modifyDefectItemToCreate, (state, action) => {
        const { id, attribute, value } = action.payload;

        state.defectItemsToCreate[id] = {
          ...state.defectItemsToCreate[id],
          [attribute]: value,
        };
      })
      .addCase(defectListActions.editDefectAttributes, (state, action) => {
        const { attribute, value } = action.payload;

        state.editedDefectAttributes[attribute] = value;
      })
      .addCase(defectListActions.openDefectList, (state, action) => {
        state.selectedDefectListId = action.payload;
      })
      .addCase(defectListActions.setDefectAssignation, (state, action) => {
        const { defectId } = action.payload;

        if (!state.defectAssignmentModifications[defectId]) {
          state.defectAssignmentModifications[defectId] = [action.payload];
        } else {
          state.defectAssignmentModifications[defectId].push(action.payload);
        }
      })
      .addCase(
        defectListActions.setMultipleDefectAssignation,
        (state, action) => {
          const assignations = action.payload;

          state.defectAssignmentModifications = assignations;
        }
      )
      .addCase(defectListActions.removeDefectAssignation, (state, action) => {
        const { userId, businessId, defectId } = action.payload;

        if (!userId) {
          state.defectAssignmentModifications[defectId] =
            state.defectAssignmentModifications[defectId].filter(
              (assignment) => assignment.businessId !== businessId
            );
        } else {
          state.defectAssignmentModifications[defectId] =
            state.defectAssignmentModifications[defectId].filter(
              (assignment) =>
                assignment.businessId !== businessId ||
                (assignment.businessId === businessId &&
                  assignment.userId !== userId)
            );
        }
      })
      .addCase(
        defectListActions.removeDefectEditAssignation,
        (state, action) => {
          const { businessId, userId } = action.payload;

          if (!userId) {
            const businessAssignments = Object.values(
              state.defectEditAssignmentsToAdd
            ).filter((assignment) => assignment.businessId === businessId);

            businessAssignments.forEach((assignment) => {
              delete state.defectEditAssignmentsToAdd[
                [`${assignment.businessId}#${assignment.userId}`]
              ];
            });
          } else {
            delete state.defectEditAssignmentsToAdd[
              [`${businessId}#${userId}`]
            ];
          }

          state.defectEditRemovedAssignations[`${businessId}#${userId}`] =
            action.payload;
        }
      )
      .addCase(defectListActions.setDefectEditAssignation, (state, action) => {
        const { businessId, userId } = action.payload;

        delete state.defectEditRemovedAssignations[`${businessId}#${userId}`];

        state.defectEditAssignmentsToAdd[`${businessId}#${userId}`] =
          action.payload;
      })
      .addCase(loadAllProjectsUsersByCompany.fulfilled, (state, action) => {
        state.projectUsersByCompany = action.payload;
      })
      .addCase(
        defectListActions.closeViewAndEditDefectListDialog,
        (state, action) => {
          state.selectedDefectListId = '';
          state.defectListAttributeModifications = {};
          state.isEditingListAttributes = false;
        }
      )
      .addCase(defectListActions.toggleEditListAttributes, (state, action) => {
        const toggleValue = action.payload;

        state.isEditingListAttributes = toggleValue;
        state.defectListAttributeModifications = {};
      })
      .addCase(defectListActions.modifyListAttribute, (state, action) => {
        state.defectListAttributeModifications = {
          ...state.defectListAttributeModifications,
          ...action.payload,
        };
      })
      .addCase(saveDefectListChanges.fulfilled, (state, action) => {
        const { updatedDefectList } = action.payload;

        defectListsAdapter.upsertOne(state.defectLists, updatedDefectList);
        state.defectListAttributeModifications = {};
        state.isEditingListAttributes = false;
      })
      .addCase(defectListActions.openAddNewDefectsDialog, (state, action) => {
        const id = uuidv4();
        state.isAddNewDefectsDialogOpen = true;
        state.defectItemsToCreate[id] = { id };
      })
      .addCase(defectListActions.closeAddNewDefectsDialog, (state, action) => {
        state.isAddNewDefectsDialogOpen = false;
        state.defectItemsToCreate = {};
        state.defectAssignmentModifications = {};
      })
      .addCase(
        defectListActions.openConfirmDefectItemDeleteDialog,
        (state, action) => {
          state.selectedDefectItemIdToDelete = action.payload;
        }
      )
      .addCase(
        defectListActions.closeConfirmDefectItemDeleteDialog,
        (state, action) => {
          state.selectedDefectItemIdToDelete = '';
        }
      )
      .addCase(deleteDefectItem.fulfilled, (state, action) => {
        defectItemsAdapter.removeOne(state.defectItems, action.payload.id);
        state.selectedDefectItemIdToDelete = '';
      })
      .addCase(defectListActions.openEditDefectItemDialog, (state, action) => {
        state.selectedDefectItemIdToEdit = action.payload;
      })
      .addCase(defectListActions.closeEditDefectItemDialog, (state, action) => {
        state.selectedDefectItemIdToEdit = '';
        state.editedDefectAttributes = {};
        state.defectEditAssignmentsToAdd = {};
        state.defectEditRemovedAssignations = {};
        state.defectImagesToAdd = [];
        state.defectEditImagesToDelete = [];
      })
      .addCase(updateDefectItem.pending, (state, action) => {
        state.isUpdatingDefect = true;
      })
      .addCase(updateDefectItem.fulfilled, (state, action) => {
        const {
          updatedDefectItem,
          createdAssignments,
          removedAssignments,
          addedDefectImages,
          deletedDefectImages,
        } = action.payload;

        defectItemsAdapter.setOne(state.defectItems, updatedDefectItem);
        defectAssignmentsAdapter.setMany(
          state.defectAssignments,
          createdAssignments
        );

        defectImagesAdapter.setMany(state.defectImages, addedDefectImages);

        if (removedAssignments && removedAssignments.length > 0) {
          const removedIds = removedAssignments.map(
            (assignment) => `${assignment.defectId}#${assignment.assigneeId}`
          );
          defectAssignmentsAdapter.removeMany(
            state.defectAssignments,
            removedIds
          );
        }

        if (deletedDefectImages && deletedDefectImages.length > 0) {
          const removedIds = deletedDefectImages.map(
            (defectImage) => defectImage.id
          );
          defectImagesAdapter.removeMany(state.defectImages, removedIds);
        }
        state.defectImagesToAdd = [];
        state.selectedDefectItemIdToEdit = '';
        state.defectEditImagesToDelete = [];
        state.editedDefectAttributes = {};
        state.isUpdatingDefect = false;

        enqueueSnackbar('ViPu päivitetty onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(updateDefectItem.rejected, (state, action) => {
        state.isUpdatingDefect = false;
      })
      .addCase(defectListActions.setDefectImageToAdd, (state, action) => {
        state.defectImagesToAdd.push(action.payload);
      })
      .addCase(defectListActions.setDefectImageToDelete, (state, action) => {
        state.defectEditImagesToDelete.push(action.payload);
      });
  },
});

export default defectListSlice.reducer;
