import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchUpdateDefectItem } from 'scenes/DefectList/services/patch-update-defect-item.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import {
  selectDefectEditAssignmentsToAdd,
  selectDefectEditImagesToDelete,
  selectDefectEditRemovedAssignations,
  selectDefectImagesToAdd,
  selectEditedDefectAttributes,
  selectSelectedDefectItemIdToEdit,
} from '../selectors/defect-list.selectors';

export const updateDefectItem = createAsyncThunk(
  'defectLists/updateDefectItem',
  async (_, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const defectItemIdToUpdate = selectSelectedDefectItemIdToEdit(getState());

    const updatedAttributes = selectEditedDefectAttributes(getState());

    const assignmentsToAdd = selectDefectEditAssignmentsToAdd(getState());

    const assignmentsToRemove = selectDefectEditRemovedAssignations(getState());

    const defectImagesToAdd = selectDefectImagesToAdd(getState());

    const defectImagesToDelete = selectDefectEditImagesToDelete(getState());

    const updatedDefectItem = await patchUpdateDefectItem(
      selectedProjectId,
      defectItemIdToUpdate,
      updatedAttributes,
      defectImagesToAdd,
      defectImagesToDelete,
      Object.values(assignmentsToAdd),
      Object.values(assignmentsToRemove)
    );

    return updatedDefectItem;
  }
);
