import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../../../utils/getCredentials';
import setSendFiling from 'scenes/Filings/store/services/setSendFiling.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const sendFiling = createAsyncThunk(
  'filings/sendFiling',
  async (filingId, { getState, rejectWithValue }) => {
    try {
      const { accessKeyId, secretAccessKey, sessionToken } = await getCredentials();
      const state = getState();
      const projectId = selectSelectedProjectId(state);

      if (!filingId) {
        return rejectWithValue('Filing ID is missing');
      }

      const params = {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      };

      const sentFiling = await setSendFiling(filingId, projectId, params);
      return { sentFiling };
    } catch (error) {
      console.error('Error in sendFiling thunk:', error);
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
