import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { postGenerateProjectOrientatedEmployeeReport } from '../services/getProjectOrientedEmployeesReport.service';

export const generateProjectOrientatedEmployeesReport = createAsyncThunk(
  'project/generateOrientatedEmployeesReport',
  async (dialogSettings, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const { language, filetype, selectedReportType } = dialogSettings;

    const generationParams = {
      language,
      filetype,
      selectedReportType,
    };

    const report = await postGenerateProjectOrientatedEmployeeReport(
      selectedProjectId,
      generationParams
    );

    return report;
  }
);
