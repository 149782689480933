import {
  FormControl,
  Grid,
  TextField,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AssignmentsSelect from 'scenes/WorkDemo/components/RecordDialog/AssignmentsSelect';
import { selectProjectAssignments } from 'store/assignments/selectors/assignment.selectors';
import { setClockOutRecordAttributes } from 'store/records/actions/records.actions';
import { selectedClockOutRecordWithUpdatedAttributes } from 'store/records/selectors/records.selectors';
import getNestedAssignmentNumber from 'utils/getNestedAssigmentNumber';
import ImageSelectComponent from './ImageSelectComponent';
import WorkTypeSelect from './WorkTypeSelect';

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.component.dialogPaper,
    padding: '8px',
    borderRadius: '2px',
    opacity: '0.9',
  },
  gridItem: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
}));

export function ClockOutInputs({
  projectId,
  setImageFiles,
  isClockOutPending,
}) {
  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const debounceDescriptionSearch = useCallback(
    debounce(setDescription, 500),
    []
  );

  const [unitAmountDialogOpen, setUnitAmountDialogOpen] = useState(false);

  const setAssignmentUnitAmount = (value) => {
    dispatch(setClockOutRecordAttributes({ assignmentUnitAmount: value }));
  };
  const setAssignmentUnitAmountDialogOpen = (value) => {
    setUnitAmountDialogOpen(value);
  };
  function setDescription(value) {
    dispatch(setClockOutRecordAttributes({ description: value }));
  }

  const projectAssignments = useSelector((state) =>
    selectProjectAssignments(state, projectId)
  );

  const clockOutDialogAttributes = useSelector(
    selectedClockOutRecordWithUpdatedAttributes
  );

  const handleTypeSelect = (value) => {
    dispatch(setClockOutRecordAttributes({ type: value }));
  };

  const handleAssignmentChange = (assignmentId) => {
    // handling assignment deselect on click if assignment already selected
    if (assignmentId !== clockOutDialogAttributes.assignment) {
      dispatch(
        setClockOutRecordAttributes({
          assignment: assignmentId,
          assignmentUnitAmount: undefined,
        })
      );
    } else {
      dispatch(
        setClockOutRecordAttributes({
          assignment: undefined,
          assignmentUnitAmount: undefined,
        })
      );
    }
  };

  const selectedAssignment = projectAssignments.find(
    (assigmnet) => assigmnet.id === clockOutDialogAttributes.assignment
  );

  const isSelectedAssignment =
    clockOutDialogAttributes.assignment && selectedAssignment;

  const disabled = isClockOutPending;

  const assigmentNumber = isSelectedAssignment
    ? getNestedAssignmentNumber(selectedAssignment, projectAssignments)
    : undefined;

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <WorkTypeSelect
            type={clockOutDialogAttributes.type}
            handleTypeSelect={handleTypeSelect}
            disabled={disabled}
          ></WorkTypeSelect>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            id={'description'}
            label={t('calendar.description')}
            variant="outlined"
            size="small"
            defaultValue={clockOutDialogAttributes.description}
            type="text"
            onChange={(e) => debounceDescriptionSearch(e.target.value)}
            multiline
            disabled={disabled}
          />
        </Grid>
        {isSelectedAssignment ? (
          <Grid item xs={12} className={classes.gridItem}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.gridItem}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id={'assignment'}
                  label={t('calendar.selected_assignment')}
                  variant="outlined"
                  size="small"
                  type="text"
                  disabled={true}
                  value={`${assigmentNumber ? assigmentNumber : ''} ${
                    selectedAssignment.name
                  }`}
                />
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id={'assignment-unitAmountGoal'}
                  label={t('common.total_work')}
                  variant="outlined"
                  size="small"
                  type="text"
                  disabled={true}
                  value={`${
                    selectedAssignment.unitAmountGoal
                      ? selectedAssignment.unitAmountGoal
                      : ''
                  } ${selectedAssignment.unit ? selectedAssignment.unit : ''}`}
                />
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id={'assignment-unitAmountGoal'}
                  label={t('calendar.work_done_today')}
                  variant="outlined"
                  size="small"
                  type="text"
                  disabled={true}
                  value={`${
                    clockOutDialogAttributes.assignmentUnitAmount
                      ? clockOutDialogAttributes.assignmentUnitAmount
                      : ''
                  } ${
                    selectedAssignment.unit &&
                    clockOutDialogAttributes.assignmentUnitAmount
                      ? selectedAssignment.unit
                      : ''
                  }`}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12} className={classes.gridItem}>
          <ImageSelectComponent
            setImageFiles={setImageFiles}
            disabled={disabled}
          ></ImageSelectComponent>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <FormControl fullWidth>
            <AssignmentsSelect
              a1={
                projectAssignments && projectAssignments[0]
                  ? projectAssignments[0]
                  : undefined
              }
              selectedAssignment={clockOutDialogAttributes.assignment}
              assignments={projectAssignments}
              update={handleAssignmentChange}
              unitAmountDialogOpen={unitAmountDialogOpen}
              setDialogOpen={(value) =>
                setAssignmentUnitAmountDialogOpen(value)
              }
              setAssignmentUnitAmount={setAssignmentUnitAmount}
              unitAmount={clockOutDialogAttributes.assignmentUnitAmount}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
