import CrumbRoute from 'components/CrumbRoute/CrumbRoute';
import Layout from 'components/Layout/Layout';
import Loading from 'components/Loading/Loading';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import { useDispatch } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom';
import { loadLatestProjectsClockedInTo } from 'store/projects/thunks/loadLatestProjectsLoadedIn.thunk';
import { loadUserProjectsAndResources } from 'store/projects/thunks/project.thunks';
import { loadPersonalClockedInRecords } from 'store/records/thunks/loadPersonalClockedInRecords.thunk';
import loadComponent from 'utils/loadComponent';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import i18next from 'i18next';

import NotificationSnackbar from 'components/NotificationSnackbar/NotificationSnackbar';
import { loadCurrentUserCards } from 'store/cards/thunks/loadCurrentUserCards.thunk';
import { openNotificationsSnackbar } from 'store/notificationSnackbar/actions/notificationSnackbar.actions';
import { loadLoggedInUserNotifications } from 'store/notifications/thunks/loadLoggedInUserNotifications.thunk';
import { loadUserRecordsFromToday } from 'store/records/thunks/loadUserRecordsFromToday.thunk';
import { loadAllUserProjectsPermissions } from './Permissions/store/thunks/loadAllUserProjectsPermissions.thunk';
import { register } from 'registerServiceWorker';

const LoadableBossview = loadComponent(() => import('./Bossview'));
const LoadableBulletinBoard = loadComponent(() => import('./BulletinBoard'));
const LoadableTimelines = loadComponent(() => import('./Timelines'));
const LoadableInfo = loadComponent(() => import('./Info'));
const LoadableProfile = loadComponent(() => import('./Profile'));
const LoadableProject = loadComponent(() => import('./Project'));
const LoadableProjects = loadComponent(() => import('./Projects'));
const LoadableProjectBank = loadComponent(() => import('./ProjectBank'));
const LoadableProjectCreate = loadComponent(() => import('./ProjectCreateNew'));
const LoadableProjectEdit = loadComponent(() => import('./ProjectEdit'));
const LoadableProjectGallery = loadComponent(() => import('./ProjectGallery'));
const LoadableProjectJoin = loadComponent(() => import('./ProjectJoin'));
const LoadableProjectPermission = loadComponent(() =>
  import('./ProjectPermission')
);
const LoadableResources = loadComponent(() => import('./Resources'));
const LoadableSettings = loadComponent(() => import('./Settings'));
const LoadableWork = loadComponent(() => import('./Work'));
const LoadableWorkDemo = loadComponent(() => import('./WorkDemo/WorkDemo'));
const LoadablePersonalWorkDemo = loadComponent(() =>
  import('./WorkDemo/PersonalWorkDemo')
);
const LoadableProjectRedirect = loadComponent(() =>
  import('./ProjectRedirect')
);
const LoadableTracking = loadComponent(() => import('./ExpenseTracking'));
const LoadableProjectForms = loadComponent(() => import('./ProjectForms'));
const LoadablePermissions = loadComponent(() => import('./Permissions'));

const LoadableUserProfile = loadComponent(() => import('./UserProfile'));
const LoadableDefectList = loadComponent(() => import('./DefectList'));


const LoadableProjectResources = loadComponent(() =>
  import('./ProjectResources')
);

const LoadableFilingsDashboard = loadComponent(() => import('./Filings'));

function App(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation('common');

  const language = i18next.language;
  if (language === 'en') {
    const locale = require(`date-fns/locale/en-US`)
    registerLocale(language, locale.default);
    setDefaultLocale(language);
  } else {
    const locale = require(`date-fns/locale/${language}`)
    registerLocale(language, locale.default);
    setDefaultLocale(language)
  }

  // let crumbpath = null;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const history = useHistory();

  useEffect(() => {
    dispatch(loadUserProjectsAndResources(history));
    dispatch(loadUserRecordsFromToday());
    dispatch(loadLatestProjectsClockedInTo());
    dispatch(loadPersonalClockedInRecords());
    dispatch(loadAllUserProjectsPermissions());
    dispatch(loadCurrentUserCards());
    dispatch(loadLoggedInUserNotifications());
  }, []);

  // Add an event listener to detect when the new service worker is activated
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    window.location.reload();
  });

  window.addEventListener('message', (event) => {
    if (event.data.type === 'swInstall') {
      console.log('swInstall listener');

      dispatch(
        openNotificationsSnackbar({
          message: 'common.app_update',
          type: 'success',
        })
      );
    }
  });

  return (
    <Layout>
      {/* <Breadcrumbs className={classes.breadcrumbs} />  BREADCRUMBS MOVED TO APPBAR.JS*/}
      {/*<CrumbRouteTwo />*/}

      <CrumbRoute path="/" title="MyLixani" />
      <Route exact path="/" render={() => <LoadableProjects />}></Route>
      <Route path="/loading" component={Loading} />
      <CrumbRoute
        path="/profile"
        component={LoadableProfile}
        title={t('navigation.profile')}
      />
      <CrumbRoute
        path="/projects"
        title={t('navigation.projects')}
        render={() => <LoadableProjects />}
      />
      <CrumbRoute
        path="/legacy-calendar/:project"
        exact
        component={LoadableWork}
        title={t('navigation.calendar')}
      />
      <CrumbRoute
        path="/calendar"
        exact
        component={LoadableWork}
        title={t('navigation.calendar')}
      />

      <CrumbRoute path="/project/:project" crumbpath={true} />

      <CrumbRoute
        path="/resources"
        component={LoadableResources}
        title={t('navigation.resources')}
      />
      {/* <CrumbRoute
        path="/bank/:parent"
        exact
        component={LoadableProjectBank}
        title={t('dashboard.project_bank')}
      /> */}
      <CrumbRoute
        path="/bank/"
        exact
        component={LoadableProjectBank}
        title={t('dashboard.project_bank')}
      />
      <CrumbRoute
        path="/info/"
        exact
        component={LoadableInfo}
        title={t('dashboard.info')}
      />
      <CrumbRoute
        path="/settings/"
        exact
        component={LoadableSettings}
        title={t('dashboard.settings')}
      />
      <CrumbRoute
        path="/settings/:idx/"
        exact
        component={LoadableSettings}
        title={t('dashboard.settings')}
      />
      <CrumbRoute
        path="/settings/:idx/:subidx"
        exact
        component={LoadableSettings}
        title={t('dashboard.settings')}
      />
      <Route path="/project" exact render={() => <LoadableProjects />} />

      <Route exact path="/:project">
        {Number.isInteger(Number(props.location.pathname.split('/')[1])) && ( // Checks if the path is number aka projectKey -> must have for crumbroutes to work with parent projects
          <Redirect to={'/project' + props.location.pathname} />
        )}
      </Route>
      <Switch>
        <CrumbRoute
          path="/project/:project/legacy-calendar/:project"
          exact
          component={LoadableWork}
          title={t('navigation.calendar')}
        />
        <CrumbRoute
          path="/project/:project/calendar/:projectId"
          exact
          component={LoadableWorkDemo}
          title={t('navigation.calendar')}
        />
        <CrumbRoute
          path="/mylixani/calendar/"
          exact
          component={LoadablePersonalWorkDemo}
          title={t('navigation.calendar')}
        />
        <CrumbRoute
          path="/project/:project/create/:parent_project"
          component={LoadableProjectCreate}
          title={t('dashboard.create_project')}
        />
        <CrumbRoute
          path="/project/create/"
          component={LoadableProjectCreate}
          title={t('dashboard.create_project')}
        />
        <CrumbRoute
          path="/project/join/:project"
          component={LoadableProjectJoin}
          title={t('dashboard.join_project')}
        />
        <CrumbRoute
          path="/project/join"
          component={LoadableProjectJoin}
          title={t('dashboard.join_project')}
        />
        <CrumbRoute
          path="/project/:project/gallery/:projectId"
          component={LoadableProjectGallery}
          title={t('gallery.gallery')}
        />
        <CrumbRoute
          path="/project/:project/:projectId/bank/"
          exact
          component={LoadableProjectBank}
          title={t('dashboard.project_bank')}
        />
        {/* <CrumbRoute
          path="/project/:project/:projectId/bank/:parent"
          component={LoadableProjectBank}
          title={t('dashboard.project_bank')}
        /> */}
        <CrumbRoute
          path="/project/:project/boss/"
          component={LoadableBossview}
          title={t('navigation.project_bossview')}
        />
        <CrumbRoute
          path="/project/:project/projectforms/:projectId"
          component={LoadableProjectForms}
          title={t('navigation.forms')}
        />
        <CrumbRoute
          path="/project/:project/defects/:projectId"
          component={LoadableDefectList}
          title={t('navigation.lists')}
        />
        <CrumbRoute
          path="/project/:project/bulletin/:projectId/:bulletinId"
          component={LoadableBulletinBoard}
          title={t('navigation.bulletin_board')}
        />
        <CrumbRoute
          path="/project/:project/bulletin/:projectId"
          component={LoadableBulletinBoard}
          title={t('navigation.bulletin_board')}
        />
        <CrumbRoute
          path="/project/:project/timelines/:projectId"
          component={LoadableTimelines}
          title={t('navigation.timelines')}
        />
        <CrumbRoute
          path="/project/:project/edit/:projectId"
          component={LoadableProjectEdit}
          title={t('dashboard.edit_project')}
        />
        <CrumbRoute
          path="/project/:projectKey/resources/:projectId"
          component={LoadableProjectResources}
          title={'Resurssit'}
        />
        <CrumbRoute
          path="/project/:project/permission/"
          component={LoadableProjectPermission}
          title={t('permission.permissions')}
        />
        <CrumbRoute
          path="/project/redirect/:project"
          component={LoadableProjectRedirect}
          title={t('permission.permissions')}
        />
        <CrumbRoute
          path="/project/:projectKey/filings/:projectId"
          component={LoadableFilingsDashboard}
          title={t('dashboard.notify')}
        />
        <CrumbRoute
          path="/project/:project/expensetracking/:projectId"
          component={LoadableTracking}
          title={t('dashboard.expense_tracking')}
        />
        <CrumbRoute
          path="/project/:projectKey/permissions/:projectId"
          component={LoadablePermissions}
          title={t('permissions.permissions-nav')}
        />
        <CrumbRoute
          path="/project/:project/:employer"
          component={LoadableProject}
          title={t('navigation.view_project')}
        />
        <CrumbRoute
          path="/project/:project"
          component={LoadableProjects}
          title={t('navigation.view_project')}
        />
        <CrumbRoute
          path="/user/:userId"
          component={LoadableUserProfile}
          title={'Käyttäjä'}
        />
      </Switch>
      <NotificationSnackbar />
    </Layout>
  );
}

export default withRouter(App);
