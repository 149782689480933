import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from '../../../utils/createSignedRequest';

export const patchUpdateDefectItem = async (
  projectId,
  defectItemId,
  updateAttributes,
  defectImagesToAdd,
  defectImagesToDelete,
  assignmentsToAdd,
  assignmentsToRemove
) => {
  const apiPath = `defect-lists/project/${projectId}/defect/${defectItemId}/update-defect-item`;

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,

    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'PATCH',
    region: Config.AWS_REGION,
    service: 'execute-api',
    body: JSON.stringify({
      updateAttributes,
      defectImagesToAdd,
      defectImagesToDelete,
      assignmentsToAdd,
      assignmentsToRemove,
    }),
    data: {
      updateAttributes,
      defectImagesToAdd,
      defectImagesToDelete,
      assignmentsToAdd,
      assignmentsToRemove,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const signedRequest = await createSignedRequest(request);
  const response = await axios(signedRequest);

  return response.data;
};
