import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getResourceTreeByDate } from '../services/getResourceTreeByDate';
import { getResourceTree } from '../services/getResourceTree';

export const loadResourceTree = createAsyncThunk(
  'expenseTracking/loadResourceTree',
  async (params, { getState, dispatch }) => {
    const state = getState();

    const fromDate = params?.fromDate;
    const toDate = params?.toDate;

    const selectedProjectId = selectSelectedProjectId(state);

    let response;

    if (fromDate && toDate) {
      response = await getResourceTreeByDate(
        selectedProjectId,
        fromDate,
        toDate
      );
      
    } else {
      response = await getResourceTree(selectedProjectId);
      
    }

    console.log('loadResourceTree response', response);
    return response;
  }
);
