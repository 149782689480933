import { createSelector } from '@reduxjs/toolkit';

export const selectRecordsState = (state) => state.records;

export const selectRecords = createSelector(
  [selectRecordsState],
  (state) => state.records.entities
);
export const selectWorkCalendarRecords = createSelector(
  [selectRecordsState],
  (state) => state.workCalendarRecords.entities
);

export const selectAllowancesCreateParams = createSelector(
  [selectRecordsState],
  (state) => Object.values(state.allowancesCreateParams.entities)
);

export const isSetAllowanceParams = createSelector(
  [selectAllowancesCreateParams],
  (allowancesParams) => allowancesParams.length > 0
);

export const selectSelectedRecordId = createSelector(
  [selectRecordsState],
  (state) => state.selectedRecordId
);

export const selectRecordAllowanceTypeAllowancesToCreate = createSelector(
  [selectAllowancesCreateParams],
  (allowances) =>
    Object.values(allowances).filter(
      (allowance) => allowance.allowanceType === 'RECORD_ALLOWANCE'
    )
);

export const selectRecordsArray = createSelector([selectRecords], (records) =>
  Object.values(records)
);

export const selectWorkCalendarRecordsArray = createSelector(
  [selectWorkCalendarRecords],
  (records) => Object.values(records)
);
