import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { loadMediaFilePermissionsByUserAndProject } from 'scenes/ProjectGallery/store/thunks/loadMediaFilePermissionsByUserAndProject';
import { initWorkCalendar } from 'scenes/WorkDemo/store/thunks/initWorkCalendar.thunk';
import { loadAdminCalendarDataInTimeframe } from 'scenes/WorkDemo/store/thunks/loadAdminCalendarDataInTimeframe.thunk';
import { loadAdminCalendarDataInTimeframeWithSearchParams } from 'scenes/WorkDemo/store/thunks/loadAdminCalendarDataInTimeframeWithSearchParams.thunk';
import { loadAdminInitialDataInTimeFrame } from 'scenes/WorkDemo/store/thunks/loadAdminInitialDataInTimeFrame';
import { loadAdminWorkCalendarDataFromExternalProject } from 'scenes/WorkDemo/store/thunks/loadAdminWorkCalendarDataFromExternalProject.thunk';
import { loadCalendarDataInTimeframe } from 'scenes/WorkDemo/store/thunks/loadCalendarDataInTimeframe.thunk';
import { loadInitialCalendarData } from 'scenes/WorkDemo/store/thunks/loadInitialCalendarData.thunk';
import { loadPersonalWorkCalendarData } from 'scenes/WorkDemo/store/thunks/my-lixani/loadPersonalWorkCalendarData.thunk';
import { approveApprovalGroup } from 'store/approvals/thunks/approveApprovalGroup.thunk';
import { createRecordsApprovalGroup } from 'store/approvals/thunks/createRecordsApprovalGroup';
import { removeApprovalGroup } from 'store/approvals/thunks/removeApprovalGroup';
import { createRecord } from 'store/records/thunks/createRecord';
import { deleteRecordThunk } from 'store/records/thunks/deleteRecordThunk';
import { editRecord } from 'store/records/thunks/editRecord';
import { openEditRecordDialog } from 'store/records/thunks/openEditRecordDialog.thunk';
import { loadResourcesAndResourceEmployersOfProject } from 'store/resources/thunks/loadResourcesAndResourceEmployersOfProject';
import {
  clearEditRecordsError,
  clearSelectedRecords,
  closeClockOutRecordDialog,
  closeCreateRecordDialog,
  closeEditRecordDialog,
  closeLocationMustDialog,
  openLocationMustDialog,
  removeAllowancesCreateParams,
  removeRecordToEdit,
  selectRecord,
  setAllowancesCreateParams,
  setBreakRecordEditAttributes,
  setClockOutRecordAttributes,
  setCreateRecordDialogAttributes,
  setEditRecordDialogAttributes,
  setEditRecordsAttributes,
  setRecordToEdit,
  setSelectedRecordsEditDialogOpen,
} from './actions/records.actions';
import { approveRecord } from './thunks/approveRecord.thunk';
import { checkRecord } from './thunks/checkRecord.thunk';
import { checkRecordSingleTime } from './thunks/checkRecordSingleTime';
import { clockInBreakRecord } from './thunks/clockInBreakRecord.thunk';
import { clockInRecord } from './thunks/clockInRecord.thunk';
import { clockOutBreakRecord } from './thunks/clockOutBreakRecord.thunk';
import { clockOutRecord } from './thunks/clockOutRecord.thunk';
import { createAbsenceRecordsByTimeframe } from './thunks/createAbsenceRecordsByTimeframe.thunk';
import { createBreakRecord } from './thunks/createBreakRecord.thunk';
import { disapproveRecord } from './thunks/disapproveRecord.thunk';
import { editRecords } from './thunks/editRecords.thunk';
import { getGeolocation } from './thunks/getGeolocation.thunk';
import { getRecordsBreakRecords } from './thunks/getRecordsBreakRecords.thunk';
import { handleBreakRecordsStatusChange } from './thunks/handleBreakRecordsStatusChange';
import { hotSwapRecordWithNewAssignment } from './thunks/hotSwapRecordWithNewAssignment';
import { loadPersonalClockedInRecords } from './thunks/loadPersonalClockedInRecords.thunk';
import { loadProjectRecordsFromToday } from './thunks/loadProjectRecordsFromToday.thunk';
import { loadRecordHistory } from './thunks/loadRecordHistory';
import { loadUserRecordsFromToday } from './thunks/loadUserRecordsFromToday.thunk';
import { openClockOutRecordDialog } from './thunks/openClockOutRecordDialog';
import { openCreateRecordDialog } from './thunks/openCreateRecordDialog';
import { setAllFilteredRecordsToEdit } from './thunks/setAllFilteredRecordsToEdit.thunk';
import { setOptimisticRecord } from './thunks/setOptimisticRecord.thunk';
import { uncheckRecord } from './thunks/uncheckRecord.thunk';
import { uncheckRecordSingleTime } from './thunks/uncheckRecordSingleTime';

const recordsAdapter = createEntityAdapter();
const workCalendarRecordsAdapter = createEntityAdapter();
const selectedRecordsAdapter = createEntityAdapter();
const clockInProjectsAdapter = createEntityAdapter();
const breakRecordsEditAttributesAdapter = createEntityAdapter();
const allowancesCreateParamsAdapter = createEntityAdapter();

export const recordsSlice = createSlice({
  name: 'records',
  initialState: {
    records: recordsAdapter.getInitialState(),
    workCalendarRecords: workCalendarRecordsAdapter.getInitialState(),
    selectedRecordId: '',
    selectedRecord: '',
    isEditingRecord: false,
    loading: false,
    loadingClockedInRecords: false,
    isProjectRecordsFromTodayLoaded: false,
    selectedRecords: selectedRecordsAdapter.getInitialState(),
    selectedRecordsEditDialogOpen: false,
    recordsEditPending: false,
    recordsEditError: false,
    recordsEditErrorMessage: '',
    selectedRecordsEditAttributes: {},
    dialogResourcesLoading: false,
    createRecordDialogOpen: false,
    createRecordDialogData: {},
    editRecordDialogOpen: false,
    clockOutRecordDialogOpen: false,
    editRecordDialogData: {},
    recordStatusChangePending: false,
    selectedRecordHistory: [],
    historyLoading: false,
    clockOutPending: false,
    locationPending: false,
    clockInPendingProjectIds: clockInProjectsAdapter.getInitialState(),
    selectedRecordBreakRecordsEditAttributes:
      breakRecordsEditAttributesAdapter.getInitialState(),
    clockOutDialogAttributes: {},
    allowancesCreateParams: allowancesCreateParamsAdapter.getInitialState(),
    isHotSwappingRecords: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadInitialCalendarData.fulfilled, (state, action) => {
        recordsAdapter.setMany(state.records, action.payload.records);
        workCalendarRecordsAdapter.setMany(
          state.workCalendarRecords,
          action.payload.records
        );
      })
      .addCase(loadCalendarDataInTimeframe.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.map((projectData) => {
          workCalendarRecordsAdapter.setMany(
            state.workCalendarRecords,
            projectData.records
          );
        });
      })
      .addCase(
        loadAdminCalendarDataInTimeframeWithSearchParams.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        loadAdminCalendarDataInTimeframeWithSearchParams.fulfilled,
        (state, action) => {
          state.loading = false;
          action.payload.map((projectData) => {
            workCalendarRecordsAdapter.setMany(
              state.workCalendarRecords,
              projectData.records
            );
          });
        }
      )
      .addCase(loadAdminCalendarDataInTimeframe.fulfilled, (state, action) => {
        action.payload.map((data) => {
          const records = data.records.filter((record) => record && record.id);
          recordsAdapter.setMany(state.records, records);
          workCalendarRecordsAdapter.setMany(
            state.workCalendarRecords,
            records
          );
        });
      })
      .addCase(
        loadAdminWorkCalendarDataFromExternalProject.fulfilled,
        (state, action) => {
          workCalendarRecordsAdapter.setMany(
            state.workCalendarRecords,
            action.payload.records
          );
        }
      )
      .addCase(loadPersonalWorkCalendarData.fulfilled, (state, action) => {
        state.loading = false;
        recordsAdapter.setMany(state.records, action.payload.records);
      })
      .addCase(editRecord.pending, (state, action) => {
        state.editRecordPending = true;
      })
      .addCase(editRecord.rejected, (state, action) => {
        state.editRecordPending = false;
      })

      .addCase(editRecord.fulfilled, (state, action) => {
        recordsAdapter.setMany(state.records, action.payload);
        workCalendarRecordsAdapter.setMany(
          state.workCalendarRecords,
          action.payload
        );
        breakRecordsEditAttributesAdapter.removeAll(
          state.selectedRecordBreakRecordsEditAttributes
        );
        state.editRecordPending = false;
      })
      .addCase(approveRecord.pending, (state, action) => {
        state.recordStatusChangePending = true;
      })
      .addCase(approveRecord.rejected, (state, action) => {
        state.recordStatusChangePending = false;
      })
      .addCase(approveRecord.fulfilled, (state, action) => {
        const updatedRecord = action.payload.updatedRecord;
        recordsAdapter.setOne(state.records, updatedRecord);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          updatedRecord
        );
        state.recordStatusChangePending = false;
      })
      .addCase(disapproveRecord.pending, (state, action) => {
        state.recordStatusChangePending = true;
      })
      .addCase(disapproveRecord.rejected, (state, action) => {
        state.recordStatusChangePending = false;
      })
      .addCase(disapproveRecord.fulfilled, (state, action) => {
        const updatedRecord = action.payload.record;
        recordsAdapter.setOne(state.records, updatedRecord);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          updatedRecord
        );

        state.recordStatusChangePending = false;
      })
      .addCase(checkRecord.pending, (state, action) => {
        state.recordStatusChangePending = true;
      })
      .addCase(checkRecord.rejected, (state, action) => {
        state.recordStatusChangePending = false;
      })
      .addCase(checkRecord.fulfilled, (state, action) => {
        recordsAdapter.setOne(state.records, action.payload);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          action.payload
        );
        state.recordStatusChangePending = false;
      })
      .addCase(uncheckRecord.pending, (state, action) => {
        state.recordStatusChangePending = true;
      })
      .addCase(uncheckRecord.rejected, (state, action) => {
        state.recordStatusChangePending = false;
      })
      .addCase(uncheckRecord.fulfilled, (state, action) => {
        recordsAdapter.setOne(state.records, action.payload);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          action.payload
        );
        state.recordStatusChangePending = false;
      })
      .addCase(initWorkCalendar.pending, (state, action) => {
        workCalendarRecordsAdapter.removeAll(state.workCalendarRecords);
      })
      .addCase(clockOutRecord.pending, (state, action) => {
        state.clockOutPending = true;
      })
      .addCase(clockOutRecord.rejected, (state, action) => {
        state.clockOutPending = false;

        enqueueSnackbar(i18n.t('notifications.errors.clock-out-error'), {
          variant: 'error',
        });
      })
      .addCase(clockOutRecord.fulfilled, (state, action) => {
        recordsAdapter.upsertMany(state.records, action.payload);
        workCalendarRecordsAdapter.upsertMany(
          state.workCalendarRecords,
          action.payload
        );
        state.clockOutPending = false;
        state.clockOutDialogAttributes = undefined;
        state.clockOutRecordDialogOpen = false;
      })
      .addCase(hotSwapRecordWithNewAssignment.pending, (state, action) => {
        state.isHotSwappingRecords = true;
      })
      .addCase(hotSwapRecordWithNewAssignment.fulfilled, (state, action) => {
        recordsAdapter.setMany(state.records, action.payload);
        workCalendarRecordsAdapter.setMany(
          state.workCalendarRecords,
          action.payload
        );
        state.isHotSwappingRecords = false;
        enqueueSnackbar('Työtehtävä onnistuneesti vaihdettu', {
          variant: 'success',
        });
      })
      .addCase(hotSwapRecordWithNewAssignment.rejected, (state, action) => {
        state.isHotSwappingRecords = false;
        enqueueSnackbar('Virhe työtehtävän vaihdossa', {
          variant: 'error',
        });
      })
      .addCase(createRecord.pending, (state, action) => {
        state.createRecordDialogOpen = false;
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        recordsAdapter.addOne(state.records, action.payload.response);
        workCalendarRecordsAdapter.addOne(
          state.workCalendarRecords,
          action.payload.response
        );
        state.createRecordDialogOpen = false;
      })
      .addCase(setOptimisticRecord.fulfilled, (state, action) => {
        recordsAdapter.setOne(state.records, action.payload);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          action.payload
        );
        clockInProjectsAdapter.removeOne(
          state.clockInPendingProjectIds,
          action.payload.project
        );
      })
      .addCase(clockInRecord.pending, (state, action) => {
        clockInProjectsAdapter.setOne(state.clockInPendingProjectIds, {
          id: action.meta.arg.projectId,
        });
      })
      .addCase(clockInRecord.fulfilled, (state, action) => {
        recordsAdapter.setOne(state.records, action.payload);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          action.payload
        );
      })
      .addCase(clockInRecord.rejected, (state, action) => {
        clockInProjectsAdapter.removeOne(
          state.clockInPendingProjectIds,
          action.meta.arg.projectId
        );
      })
      .addCase(clockInBreakRecord.fulfilled, (state, action) => {
        recordsAdapter.setOne(state.records, action.payload);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          action.payload
        );
      })
      .addCase(clockInBreakRecord.rejected, (state, action) => {
        recordsAdapter.removeOne(state.records, action.payload);
        workCalendarRecordsAdapter.removeOne(
          state.workCalendarRecords,
          action.payload
        );
        enqueueSnackbar(
          i18n.t('notifications.errors.break-clock-in-rejected'),
          {
            variant: 'error',
          }
        );
      })
      .addCase(clockOutBreakRecord.pending, (state, action) => {
        state.clockOutPending = true;
      })
      .addCase(clockOutBreakRecord.rejected, (state, action) => {
        state.clockOutPending = false;
        enqueueSnackbar(
          i18n.t('notifications.errors.break-clock-out-rejected'),
          {
            variant: 'error',
          }
        );
      })
      .addCase(clockOutBreakRecord.fulfilled, (state, action) => {
        recordsAdapter.upsertOne(state.records, action.payload);
        workCalendarRecordsAdapter.upsertOne(
          state.workCalendarRecords,
          action.payload
        );
        state.clockOutPending = false;
      })

      .addCase(deleteRecordThunk.fulfilled, (state, action) => {
        const deletedRecordIds = action.payload.map(
          (deletedRecord) => deletedRecord.id
        );
        recordsAdapter.removeMany(state.records, deletedRecordIds);
        workCalendarRecordsAdapter.removeMany(
          state.workCalendarRecords,
          deletedRecordIds
        );
      })
      .addCase(openEditRecordDialog.fulfilled, (state, action) => {
        state.editRecordDialogOpen = true;
        state.editRecordDialogData = action.payload;
      })
      .addCase(closeEditRecordDialog, (state, action) => {
        state.editRecordDialogOpen = false;
        state.editRecordDialogData = {};
        state.selectedRecordHistory = [];
        breakRecordsEditAttributesAdapter.removeAll(
          state.selectedRecordBreakRecordsEditAttributes
        );
      })
      .addCase(setEditRecordDialogAttributes, (state, action) => {
        state.editRecordDialogData = {
          ...state.editRecordDialogData,
          ...action.payload,
        };
      })
      .addCase(selectRecord, (state, action) => {
        state.selectedRecordId = action.payload;
      })
      .addCase(loadPersonalClockedInRecords.pending, (state, action) => {
        state.loadingClockedInRecords = true;
      })
      .addCase(loadPersonalClockedInRecords.fulfilled, (state, action) => {
        recordsAdapter.setMany(state.records, action.payload);
        workCalendarRecordsAdapter.setMany(
          state.workCalendarRecords,
          action.payload
        );
        state.loadingClockedInRecords = false;
      })
      .addCase(loadPersonalClockedInRecords.rejected, (state, action) => {
        state.loadingClockedInRecords = false;
        state.error = action.error;
      })
      .addCase(loadProjectRecordsFromToday.pending, (state, action) => {
        state.isProjectRecordsFromTodayLoaded = false;
      })
      .addCase(loadProjectRecordsFromToday.fulfilled, (state, action) => {
        state.records = recordsAdapter.setMany(
          state.records,
          action.payload.projectRecords
        );
        state.isProjectRecordsFromTodayLoaded = true;
      })
      .addCase(loadProjectRecordsFromToday.rejected, (state, action) => {
        state.isProjectRecordsFromTodayLoaded = true;
      })
      .addCase(loadUserRecordsFromToday.fulfilled, (state, action) => {
        state.records = recordsAdapter.setMany(state.records, action.payload);
      })

      .addCase(setRecordToEdit, (state, action) => {
        selectedRecordsAdapter.addOne(state.selectedRecords, action.payload);
      })
      .addCase(removeRecordToEdit, (state, action) => {
        selectedRecordsAdapter.removeOne(state.selectedRecords, action.payload);
      })
      .addCase(setAllFilteredRecordsToEdit.fulfilled, (state, action) => {
        selectedRecordsAdapter.setMany(state.selectedRecords, action.payload);
      })
      .addCase(clearSelectedRecords, (state, action) => {
        selectedRecordsAdapter.removeAll(state.selectedRecords);
      })
      .addCase(setSelectedRecordsEditDialogOpen, (state, action) => {
        state.selectedRecordsEditDialogOpen = action.payload;
      })
      .addCase(editRecords.pending, (state, action) => {
        state.recordsEditPending = true;
      })
      .addCase(editRecords.rejected, (state, action) => {
        state.recordsEditPending = false;
        state.recordsEditError = true;
        state.selectedRecordsEditAttributes = {};

        if (action.payload && action.payload.message) {
          state.recordsEditErrorMessage = action.payload.message;
        } else {
          state.recordsEditErrorMessage = '';
        }
      })
      .addCase(editRecords.fulfilled, (state, action) => {
        state.recordsEditPending = false;
        state.selectedRecordsEditDialogOpen = false;
        selectedRecordsAdapter.removeAll(state.selectedRecords);
        state.records = recordsAdapter.setMany(
          state.records,
          action.payload.records
        );
        workCalendarRecordsAdapter.setMany(
          state.workCalendarRecords,
          action.payload.records
        );
        state.selectedRecordsEditAttributes = {};
      })
      .addCase(clearEditRecordsError, (state, action) => {
        state.recordsEditError = false;
      })
      .addCase(setEditRecordsAttributes, (state, action) => {
        state.selectedRecordsEditAttributes = action.payload;
      })
      .addCase(
        loadResourcesAndResourceEmployersOfProject.pending,
        (state, action) => {
          state.dialogResourcesLoading = true;
        }
      )
      .addCase(
        loadResourcesAndResourceEmployersOfProject.rejected,
        (state, action) => {
          state.dialogResourcesLoading = false;
        }
      )
      .addCase(
        loadResourcesAndResourceEmployersOfProject.fulfilled,
        (state, action) => {
          state.dialogResourcesLoading = false;
        }
      )
      .addCase(
        loadMediaFilePermissionsByUserAndProject.fulfilled,
        (state, action) => {
          const records = action.payload.records.filter((record) => record);
          recordsAdapter.setMany(state.records, records);
        }
      )
      .addCase(openCreateRecordDialog.fulfilled, (state, action) => {
        state.createRecordDialogOpen = true;
        state.createRecordDialogData = action.payload;
      })
      .addCase(closeCreateRecordDialog, (state) => {
        state.createRecordDialogOpen = false;
        state.createRecordDialogData = {};
      })
      .addCase(setCreateRecordDialogAttributes, (state, action) => {
        state.createRecordDialogData = {
          ...state.createRecordDialogData,
          ...action.payload,
        };
      })
      .addCase(setClockOutRecordAttributes, (state, action) => {
        state.clockOutDialogAttributes = {
          ...state.clockOutDialogAttributes,
          ...action.payload,
        };
      })

      .addCase(loadRecordHistory.pending, (state, action) => {
        state.selectedRecordHistory = [];
        state.historyLoading = true;
      })
      .addCase(loadRecordHistory.rejected, (state, action) => {
        state.historyLoading = false;
      })
      .addCase(loadRecordHistory.fulfilled, (state, action) => {
        state.selectedRecordHistory = action.payload;
        state.historyLoading = false;
      })
      .addCase(createRecordsApprovalGroup.fulfilled, (state, action) => {
        const records = action.payload.records;
        recordsAdapter.setMany(state.records, records);
        workCalendarRecordsAdapter.setMany(state.workCalendarRecords, records);
      })
      .addCase(removeApprovalGroup.fulfilled, (state, action) => {
        const records = action.payload.records;
        recordsAdapter.setMany(state.records, records);
        workCalendarRecordsAdapter.setMany(state.workCalendarRecords, records);
      })

      .addCase(checkRecordSingleTime.fulfilled, (state, action) => {
        recordsAdapter.setOne(state.records, action.payload);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          action.payload
        );
        state.recordStatusChangePending = false;
      })
      .addCase(checkRecordSingleTime.pending, (state, action) => {
        state.recordStatusChangePending = true;
      })
      .addCase(checkRecordSingleTime.rejected, (state, action) => {
        state.recordStatusChangePending = false;
      })
      .addCase(uncheckRecordSingleTime.fulfilled, (state, action) => {
        recordsAdapter.setOne(state.records, action.payload);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          action.payload
        );
        state.recordStatusChangePending = false;
      })
      .addCase(uncheckRecordSingleTime.pending, (state, action) => {
        state.recordStatusChangePending = true;
      })
      .addCase(uncheckRecordSingleTime.rejected, (state, action) => {
        state.recordStatusChangePending = false;
      })
      .addCase(approveApprovalGroup.fulfilled, (state, action) => {
        recordsAdapter.setMany(state.records, action.payload.records);
        workCalendarRecordsAdapter.setMany(
          state.workCalendarRecords,
          action.payload.records
        );
      })
      .addCase(getGeolocation.fulfilled, (state, action) => {
        state.locationPending = false;
      })
      .addCase(getGeolocation.pending, (state, action) => {
        console.log('location fetch started');
        state.locationPending = true;
      })
      .addCase(getGeolocation.rejected, (state, action) => {
        console.log('Error getting location');
        state.locationPending = false;
      })
      .addCase(openLocationMustDialog, (state, action) => {
        state.locationMustDialog = action.payload;
      })
      .addCase(closeLocationMustDialog, (state, action) => {
        state.locationMustDialog = undefined;
      })
      .addCase(setBreakRecordEditAttributes, (state, action) => {
        breakRecordsEditAttributesAdapter.upsertOne(
          state.selectedRecordBreakRecordsEditAttributes,
          action.payload
        );
      })
      .addCase(handleBreakRecordsStatusChange.fulfilled, (state, action) => {
        breakRecordsEditAttributesAdapter.setMany(
          state.selectedRecordBreakRecordsEditAttributes,
          action.payload
        );
      })
      .addCase(createBreakRecord.pending, (state, action) => {
        state.editRecordPending = true;
      })
      .addCase(createBreakRecord.rejected, (state, action) => {
        state.editRecordPending = false;
      })
      .addCase(createBreakRecord.fulfilled, (state, action) => {
        state.editRecordPending = false;

        recordsAdapter.setOne(state.records, action.payload);
        workCalendarRecordsAdapter.setOne(
          state.workCalendarRecords,
          action.payload
        );
      })
      .addCase(getRecordsBreakRecords.fulfilled, (state, action) => {
        recordsAdapter.upsertMany(state.records, action.payload);
        workCalendarRecordsAdapter.upsertMany(
          state.workCalendarRecords,
          action.payload
        );
      })
      .addCase(loadAdminInitialDataInTimeFrame.fulfilled, (state, action) => {
        workCalendarRecordsAdapter.upsertMany(
          state.workCalendarRecords,
          action.payload.records
        );
      })
      .addCase(openClockOutRecordDialog.pending, (state, action) => {
        state.clockOutDialogAttributes = {};
      })
      .addCase(openClockOutRecordDialog.fulfilled, (state, action) => {
        allowancesCreateParamsAdapter.removeAll(state.allowancesCreateParams);

        state.selectedRecordId = action.payload;
        state.clockOutRecordDialogOpen = true;
      })
      .addCase(closeClockOutRecordDialog, (state, action) => {
        state.clockOutRecordDialogOpen = false;
        state.selectedRecordId = '';
        state.clockOutDialogAttributes = {};
        allowancesCreateParamsAdapter.removeAll(state.allowancesCreateParams);
      })
      .addCase(setAllowancesCreateParams, (state, action) => {
        allowancesCreateParamsAdapter.setOne(
          state.allowancesCreateParams,
          action.payload
        );
      })
      .addCase(removeAllowancesCreateParams, (state, action) => {
        allowancesCreateParamsAdapter.removeOne(
          state.allowancesCreateParams,
          action.payload
        );
      })
      .addCase(createAbsenceRecordsByTimeframe.rejected, (state, action) => {
        enqueueSnackbar(
          i18n.t('notifications.errors.add-absence-records_error'),
          {
            variant: 'error',
          }
        );
      })
      .addCase(createAbsenceRecordsByTimeframe.fulfilled, (state, action) => {
        recordsAdapter.upsertMany(state.records, action.payload);
        workCalendarRecordsAdapter.upsertMany(
          state.workCalendarRecords,
          action.payload
        );
        enqueueSnackbar(
          i18n.t('notifications.success.add-absence-records_success'),
          {
            variant: 'success',
          }
        );
      });
  },
});
