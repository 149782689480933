import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../../../utils/getCredentials';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import setRequestCorrectionFiling from '../services/setRequestCorrectionFiling.service';

export const requestCorrectionFiling = createAsyncThunk(
  'filings/requestCorrectionFiling',
  async (data, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const state = getState();

    const projectId = selectSelectedProjectId(state);

    if (!data) {
      return;
    }

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const createdFiling = await setRequestCorrectionFiling(data, projectId, params);
    return { createdFiling };
  }
);
