import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateNewDefects } from 'scenes/DefectList/services/post-create-new-defects.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import {
  selectDefectAssignmentModifications,
  selectDefectImagesToAdd,
  selectDefectItemsToCreate,
  selectSelectedDefectListId,
} from '../selectors/defect-list.selectors';
import { closeAddNewDefectsDialog } from '../actions/defectListActions';

export const createNewDefects = createAsyncThunk(
  'defectLists/createNewDefects',
  async (_, { getState, dispatch }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const selectedDefectListId = selectSelectedDefectListId(getState());

    const defectItemsToCreate = selectDefectItemsToCreate(getState());

    const defectItemAssignationsToCreate = selectDefectAssignmentModifications(
      getState()
    );

    const defectImagesToAdd = selectDefectImagesToAdd(getState());

    const createdListData = await postCreateNewDefects(
      selectedProjectId,
      selectedDefectListId,
      Object.values(defectItemsToCreate),
      Object.values(defectItemAssignationsToCreate).flat(),
      defectImagesToAdd
    );

    dispatch(closeAddNewDefectsDialog());

    return createdListData;
  }
);
