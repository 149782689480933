import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadActiveAssignmentsForBusiness } from 'store/assignments/thunks/loadActiveAssignmentsForBusiness';
import { createProject } from 'store/projects/thunks/createProject.thunk';
import {
  addFilter,
  clearExpensesTrackingData,
  getProjectAssignmentTree,
  removeFilter,
  resetFilters,
  setAllProjectAssignmentsLoaded,
  setAllProjectAssignmentsLoadedAmount,
  setFilters,
  updateAssignmentsFromGraphqlMutate
} from './actions/expenses-tracking.actions';
import { loadCompanyInvoiceTotals } from './thunks/loadCompanyInvoiceTotals';
import { loadParentAndSubProjects } from './thunks/loadParentAndSubProjects';
import { loadResourceTree } from './thunks/loadResourceTree';
import { updateResource } from './thunks/updateResource';
import { updateResources } from './thunks/updateResources';

const resourceTreeAdapter = createEntityAdapter();
const parentAndSubProjectsAdapter = createEntityAdapter();
const projectAssignmentsAdapter = createEntityAdapter();
const companyTotalsAdapter = createEntityAdapter();
const employerAdapter = createEntityAdapter();
const resourceAdapter = createEntityAdapter();
const recordAdapter = createEntityAdapter();
const resetState = {
  resourceTree: resourceTreeAdapter.getInitialState(),
  parentAndSubProjects: parentAndSubProjectsAdapter.getInitialState(),
  loadingResourceTree: false,
  projectAssignments: projectAssignmentsAdapter.getInitialState(),
  companyInvoiceTotals: companyTotalsAdapter.getInitialState(),
  loadingCompanyInvoiceTotals: false,
  allProjectAssignmentsLoaded: false,
  allProjectAssignmentsLoadedAmount: 0,
  activeFilters: [],

  employers: employerAdapter.getInitialState(),
  resources: resourceAdapter.getInitialState(),
  records: recordAdapter.getInitialState(),
};

export const expenseTrackingSlice = createSlice({
  name: 'expenseTracking',
  initialState: {
    resourceTree: resourceTreeAdapter.getInitialState(),
    parentAndSubProjects: parentAndSubProjectsAdapter.getInitialState(),
    loadingResourceTree: false,
    projectAssignments: projectAssignmentsAdapter.getInitialState(),
    companyInvoiceTotals: companyTotalsAdapter.getInitialState(),
    loadingCompanyInvoiceTotals: false,
    allProjectAssignmentsLoaded: false,
    allProjectAssignmentsLoadedAmount: 0,
    activeFilters: [],

    employers: employerAdapter.getInitialState(),
    resources: resourceAdapter.getInitialState(),
    records: recordAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadResourceTree.pending, (state) => ({
        ...state,
        loadingResourceTree: true,
      }))
      .addCase(loadResourceTree.fulfilled, (state, action) => {
        // resourceTreeAdapter.setAll(state.resourceTree, action.payload);
        console.log('loadResourceTree.fulfilled', action.payload);
        resourceAdapter.setAll(state.resources, action.payload.resources);
        employerAdapter.setAll(
          state.employers,
          action.payload.employers
        );
        state.loadingResourceTree = false;
      })
      .addCase(loadResourceTree.rejected, (state, action) => ({
        ...state,
        loadingResourceTree: false,
        error: action.error,
      }))
      // .addCase(loadResourcesOfProjectWithEmployerData.pending, (state) => ({
      //   ...state,
      //   loadingResourceTree: true,
      // }))
      // .addCase(loadResourcesOfProjectWithEmployerData.fulfilled, (state, action) => {
      //   resourceAdapter.setAll(state.resources, action.payload.resources);
      //   employerAdapter.setAll(state.employers, action.payload.employerProjects);

      //   state.loadingResourceTree = false;
      // })
      // .addCase(loadResourcesOfProjectWithEmployerData.rejected, (state, action) => ({
      //   ...state,
      //   loadingResourceTree: false,
      //   error: action.error,
      // }))
      .addCase(loadCompanyInvoiceTotals.fulfilled, (state, action) => {
        state.companyInvoiceTotals = action.payload;
        state.loadingCompanyInvoiceTotals = false;
      })
      .addCase(loadCompanyInvoiceTotals.pending, (state) => ({
        ...state,
        loadingCompanyInvoiceTotals: true,
      }))
      .addCase(loadCompanyInvoiceTotals.rejected, (state, action) => ({
        ...state,
        error: action.error,
        loadingCompanyInvoiceTotals: false,
      }))

      .addCase(loadParentAndSubProjects.pending, (state) => ({
        ...state,
        loadingParentAndSubProjects: true,
      }))
      .addCase(loadParentAndSubProjects.fulfilled, (state, action) => {
        parentAndSubProjectsAdapter.setAll(
          state.parentAndSubProjects,
          action.payload
        );
        state.loadingParentAndSubProjects = false;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        parentAndSubProjectsAdapter.addOne(
          state.parentAndSubProjects,
          action.payload.newProject
        );
      })
      .addCase(loadParentAndSubProjects.rejected, (state, action) => ({
        ...state,
        loadingParentAndSubProjects: false,
        error: action.error,
      }))
      .addCase(updateResource.pending, (state) => ({
        ...state,
        loadingUpdateResources: true,
      }))
      .addCase(updateResource.fulfilled, (state, action) => {
        resourceAdapter.upsertOne(state.resources, action.payload);
        state.loadingUpdateResources = false;
      })
      .addCase(updateResource.rejected, (state, action) => ({
        ...state,
        loadingUpdateResources: false,
        error: action.error,
      }))
      .addCase(updateResources.pending, (state) => ({
        ...state,
        loadingUpdateResources: true,
      }))
      .addCase(updateResources.fulfilled, (state, action) => {
        console.log('updateResources.fulfilled', action.payload);
        resourceAdapter.upsertMany(state.resources, action.payload);
        state.loadingUpdateResources = false;
      })
      .addCase(updateResources.rejected, (state, action) => ({
        ...state,
        loadingUpdateResources: false,
        error: action.error,
      }))
      .addCase(loadActiveAssignmentsForBusiness.pending, (state) => ({
        ...state,
        loadingProjectAssignments: true,
      }))
      .addCase(loadActiveAssignmentsForBusiness.fulfilled, (state, action) => {
        projectAssignmentsAdapter.setMany(
          state.projectAssignments,
          action.payload
        );
        state.loadingProjectAssignments = false;
      })
      .addCase(loadActiveAssignmentsForBusiness.rejected, (state, action) => ({
        ...state,
        loadingProjectAssignments: false,
        error: action.error,
      }))
      .addCase(getProjectAssignmentTree, (state, action) => ({
        ...state,
        projectAssignmentTree: action.payload,
      }))
      .addCase(setAllProjectAssignmentsLoaded, (state, action) => ({
        ...state,
        allProjectAssignmentsLoaded: action.payload,
      }))
      .addCase(setAllProjectAssignmentsLoadedAmount, (state, action) => ({
        ...state,
        allProjectAssignmentsLoadedAmount: action.payload,
      }))
      .addCase(clearExpensesTrackingData, () => resetState)
      .addCase(updateAssignmentsFromGraphqlMutate, (state, action) => {
        console.log('updateAssignmentsFromGraphqlMutate', action);
        if (action.payload.length) {
          projectAssignmentsAdapter.upsertMany(
            state.projectAssignments,
            action.payload
          );
        } else {
          projectAssignmentsAdapter.upsertOne(
            state.projectAssignments,
            action.payload
          );
        }
      })
      .addCase(setFilters, (state, action) => ({
        ...state,
        activeFilters: action.payload,
      }))
      .addCase(addFilter, (state, action) => ({
        ...state,
        activeFilters: [...state.activeFilters, action.payload],
      }))
      .addCase(removeFilter, (state, action) => ({
        ...state,
        activeFilters: state.activeFilters.filter(
          (filter) => filter !== action.payload
        ),
      }))
      .addCase(resetFilters, (state) => ({
        ...state,
        activeFilters: [],
      }));
  },
});
