import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateDefectList } from 'scenes/DefectList/services/post-create-defect-list.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import {
  selectDefectAssignmentModifications,
  selectDefectImagesToAdd,
  selectDefectItemsToCreate,
} from '../selectors/defect-list.selectors';

export const createDefectList = createAsyncThunk(
  'defectLists/createDefectList',
  async (defectListAttributes, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const defectItemsToCreate = selectDefectItemsToCreate(getState());

    const defectItemAssignationsToCreate = selectDefectAssignmentModifications(
      getState()
    );

    const defectImagesToCreate = selectDefectImagesToAdd(getState());

    const createdListData = await postCreateDefectList(
      selectedProjectId,
      defectListAttributes,
      Object.values(defectItemsToCreate),
      Object.values(defectItemAssignationsToCreate).flat(),
      defectImagesToCreate
    );

    return createdListData;
  }
);
