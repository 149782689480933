import i18n from 'i18next';
import moment from 'moment';
import { sortByClockInAt, sortByProject, sortByRecordFirstName } from '../sort';
import { RECORD_TYPE } from 'LixaniAPI/enums';
const ExcelJS = require('exceljs');

export const docName = (settings) => {
  const documentName = `${i18n.t('reportName.' + settings.reportName)}`;
  const documentFilename = `${i18n.t(
    'reportFilename.' + settings.reportName
  )}_${moment().format('YYYY-MM-DD')}`;
  return { documentName, documentFilename };
};

export const createReport = (reportData, settings, generationParams) => {
  const { records, fromDate, toDate, projectName, resources, utcOffset } =
    reportData;

  const {
    isDetailedReportSelected,
    isRecordBasedReductionsActive,
    paidCoffeeBreakMinutes,
  } = generationParams;

  const recordsWithFoundResource = records.filter((record) => record.resource);

  let offsetValue = utcOffset.split(':')[0];
  offsetValue = offsetValue.slice(1);
  offsetValue = parseInt(offsetValue);

  const tempFromDate = moment(fromDate).utc().add(offsetValue, 'hours');

  //-- Sort records by clockInAt and project and finally by resources
  recordsWithFoundResource.sort((a, b) => {
    sortByClockInAt(a, b, settings.language);
  });
  recordsWithFoundResource.sort((a, b) => {
    sortByProject(a, b, settings.language);
  });

  recordsWithFoundResource.sort((a, b) => {
    sortByRecordFirstName(a, b, settings.language);
  });

  let _resources = {};
  let workDaysTotal = [];

  recordsWithFoundResource.forEach((record) => {
    const resource = record.resource;
    if (!(resource.user in _resources)) _resources[resource.user] = {};
    if (!(record.project in _resources[resource.user]))
      _resources[resource.user][record.project] = [];
    _resources[resource.user][record.project].push(record);

    const date = moment(record.clockInAt).format('YYYYMMDD');
    if (!workDaysTotal.includes(date)) {
      workDaysTotal.push(date);
    }
  });

  const mainReportHeader = () => {
    const title = {
      richText: [{ text: projectName, font: { bold: true, size: 18 } }],
    };
    const date = {
      richText: [
        {
          text: moment().format('DD.MM.YYYY'),
          font: { bold: true },
        },
      ],
    };
    const dateRange = {
      richText: [
        {
          text: `${moment(tempFromDate).format('DD.MM.')}-${moment(
            toDate
          ).format('DD.MM.YYYY')}`,
          font: { bold: true },
        },
      ],
    };
    const workDaysText = {
      richText: [
        {
          text: workDaysTotal.length + ' ' + i18n.t('reports.work_days_short'),
          font: { bold: true, color: { argb: 'FFFF0000' } },
        },
      ],
    };
    let headerRows = [
      ['', title, '', '', '', '', '', '', ''],
      ['', dateRange, '', '', workDaysText, '', '', date, ''],
    ];
    return headerRows;
  };

  const mainTableHeader = () => {
    let titles = [
      '',
      `${i18n.t('reports.name').toUpperCase()} / ${i18n.t('reports.site')}`,
      i18n.t('reports.sites'),
      i18n.t('reports.description'),
      i18n.t('reports.letter'),
      i18n.t('reports.work_days_short').toUpperCase(),
      i18n.t('reports.hours').toUpperCase(),
      'Tunnit leikkurilla'.toUpperCase(),
      i18n.t('reports.hours_with_break_reductions').toUpperCase(),
      'Leikatut tunnit taukoväh.'.toUpperCase(),
      '',
      //i18n.t('reports.total_contracts') + ' €',
    ];

    titles = titles.map((x, idx) => {
      if ([4, 5, 6, 7, 8, 9].includes(idx)) {
        x = {
          richText: [
            {
              text: x,
              font: {
                bold: true,
                color: { argb: 'FFFF0000' },
              },
            },
          ],
        };
      } else {
        x = { richText: [{ text: x, font: { bold: true } }] };
      }
      return x;
    });

    return titles;
  };

  const mainTableRows = () => {
    let rows = [];

    let reportUsersTotalHours = 0;
    let reportUsersTotalCroppedHours = 0;
    let reportUsersTotalWorkHours = 0;
    let reportUsersTotalCroppedWorkHours = 0;

    // counted each day total hours for normal and cropped record data seperately
    let countedDayHoursForEachUser = {};

    // counted each day reductions made based on cropped and non cropped records seperately
    let croppedDayReductionsForUsers = {};
    let dayReductionsForUsers = {};

    Object.keys(_resources).forEach((userKey, idx) => {
      let resourceRows = [];

      const resource = resources[userKey].resource;

      const projects = _resources[userKey];
      const number = idx + 1;

      let resourceName = `${resource.firstName} ${resource.lastName}`;
      if (settings.lastNameFirst) {
        resourceName = `${resource.lastName} ${resource.firstName}`;
      }

      let workDays = [];
      let totalHours = 0;
      let totalHoursCropped = 0;
      let totalWorkHours = 0;
      let totalWorkHoursCropped = 0;

      Object.keys(projects).forEach((projectId) => {
        const projectBreakRecords = Object.values(projects[projectId]).filter(
          (record) => record.type.includes('BREAK')
        );

        projects[projectId].forEach((record) => {
          if (
            record.type === RECORD_TYPE.WORK ||
            record.type === RECORD_TYPE.PROJECTWORK
          ) {
            // record uncropped hour data
            const clockIn = moment(record.clockInAt);
            const clockOut = moment(record.clockOutAt);
            const recordHours = clockOut.diff(clockIn, 'hours', true);

            // record cropped hour data
            const croppedClockIn = moment(record.croppedClockInAt);
            const croppedClockOut = moment(record.croppedClockOutAt);
            const croppedRecordHours = croppedClockOut.diff(
              croppedClockIn,
              'hours',
              true
            );

            let breakHourTotals = 0;
            let croppedBreakHourTotals = 0;

            if (isRecordBasedReductionsActive) {
              const recordBreaks = projectBreakRecords.filter(
                (breakRecord) => breakRecord.parentRecordId === record.id
              );

              if (recordBreaks && recordBreaks.length > 0) {
                let recordCoffeeBreakTotalHours = 0;
                let recordMealBreakTotalHours = 0;
                let recordBreakTotalHours = 0;

                recordBreaks.forEach((breakRecord) => {
                  const breakClockIn = moment(breakRecord.clockInAt);
                  const breakClockOut = moment(breakRecord.clockOutAt);
                  const breakHours = breakClockOut.diff(
                    breakClockIn,
                    'hours',
                    true
                  );

                  if (breakRecord.type.includes('COFFEE')) {
                    recordCoffeeBreakTotalHours += breakHours;
                  } else if (breakRecord.type.includes('MEAL')) {
                    recordMealBreakTotalHours += breakHours;
                  } else {
                    recordBreakTotalHours += breakHours;
                  }
                });

                const calculatedBreakHours =
                  calculateBreakRecordTotalReductions(
                    recordBreakTotalHours,
                    recordMealBreakTotalHours,
                    recordCoffeeBreakTotalHours,
                    paidCoffeeBreakMinutes
                  );

                breakHourTotals += calculatedBreakHours;
                croppedBreakHourTotals += calculatedBreakHours;
              }
            } else {
              const resourceUserId = record.resource.user;

              const recordDay = record.clockInAt.split('T')[0];

              if (!countedDayHoursForEachUser[resourceUserId]?.[recordDay]) {
                countedDayHoursForEachUser[resourceUserId] = {
                  ...countedDayHoursForEachUser[resourceUserId],
                  [recordDay]: {
                    croppedHours: croppedRecordHours,
                    hours: recordHours,
                  },
                };
              } else {
                countedDayHoursForEachUser[resourceUserId][recordDay].hours +=
                  recordHours;

                countedDayHoursForEachUser[resourceUserId][
                  recordDay
                ].croppedHours += croppedRecordHours;
              }

              if (
                countedDayHoursForEachUser[resourceUserId]?.[recordDay]
                  .croppedHours > 6 &&
                !croppedDayReductionsForUsers[resourceUserId]?.[recordDay]
              ) {
                croppedDayReductionsForUsers[resourceUserId] = {
                  ...croppedDayReductionsForUsers[resourceUserId],
                  [recordDay]: 0.5,
                };
                croppedBreakHourTotals += 0.5;
              }
              if (
                countedDayHoursForEachUser[resourceUserId]?.[recordDay].hours >
                  6 &&
                !dayReductionsForUsers[resourceUserId]?.[recordDay]
              ) {
                dayReductionsForUsers[resourceUserId] = {
                  ...dayReductionsForUsers[resourceUserId],
                  [recordDay]: 0.5,
                };
                breakHourTotals += 0.5;
              }
            }

            const project = record.project;

            const croppedWorkHours =
              croppedRecordHours - croppedBreakHourTotals;
            const workHours = recordHours - breakHourTotals;
            totalHours += recordHours;
            totalHoursCropped += croppedRecordHours;

            totalWorkHours += workHours;
            totalWorkHoursCropped += croppedWorkHours;
            let description = record.description ? record.description : '';
            let assignmentNumber = '';
            if (record.assignment && record.assignment.id) {
              const assignment = record.assignment;
              assignmentNumber = !isNaN(assignment?.name.charAt(0))
                ? assignment?.name.split(' ')[0]
                : '';
              let assignmentName = !isNaN(assignment?.name.charAt(0))
                ? assignment?.name.split(' ').slice(1).join(' ')
                : assignment?.name;
              description = `${assignmentName} ${description}`;
            }

            resourceRows.push([
              number,
              project?.name,
              project.projectKey,
              description ? description : '',
              assignmentNumber,
              '',
              recordHours.toFixed(2).replace('.', ','),
              croppedRecordHours.toFixed(2).replace('.', ','),
              workHours.toFixed(2).replace('.', ','),
              croppedWorkHours.toFixed(2).replace('.', ','),
              '',
            ]);

            const date = clockIn.format('YYYYMMDD');
            if (!workDays.includes(date)) {
              workDays.push(date);
            }
          }
        });
      });

      reportUsersTotalHours += totalHours;
      reportUsersTotalCroppedHours += totalHoursCropped;
      reportUsersTotalWorkHours += totalWorkHours;
      reportUsersTotalCroppedWorkHours += totalWorkHoursCropped;

      const finalTotalHours = totalHours.toFixed(2).replace('.', ',');
      const finalTotalHoursCropped = totalHoursCropped
        .toFixed(2)
        .replace('.', ',');

      let employeeRow = [
        number,
        resourceName,
        '',
        '',
        '',
        workDays.length,
        finalTotalHours,
        finalTotalHoursCropped,
        totalWorkHours.toFixed(2).replace('.', ','),
        totalWorkHoursCropped.toFixed(2).replace('.', ','),
        '',
      ];

      employeeRow = employeeRow.map((x) =>
        x
          ? {
              richText: [
                {
                  text: x,
                  font: {
                    bold: true,
                    color: isDetailedReportSelected ? { argb: 'FFFF0000' } : {},
                  },
                },
              ],
            }
          : ''
      );

      const emptyRow = ['', '', '', '', '', '', '', '', '', '', ''];

      const detailedRows = [employeeRow, ...resourceRows, emptyRow];

      const summaryRows = [employeeRow];

      resourceRows = isDetailedReportSelected ? detailedRows : summaryRows;

      rows = [...rows, ...resourceRows];
    });

    const reportTotalHoursRow = [
      '',
      'Yhteensä',
      '',
      '',
      '',
      '',
      reportUsersTotalHours.toFixed(2).replace('.', ','),
      reportUsersTotalCroppedHours.toFixed(2).replace('.', ','),
      reportUsersTotalWorkHours.toFixed(2).replace('.', ','),
      reportUsersTotalCroppedWorkHours.toFixed(2).replace('.', ','),
      '',
    ];
    return [...rows, reportTotalHoursRow];
  };

  const calcTableWidths = (tableheader) => {
    return tableheader.map(() => 'auto');
  };

  const mainHeader = mainReportHeader();
  console.log('mainheader', JSON.stringify(mainHeader));

  const tableHeader = mainTableHeader();
  console.log('tableheader', JSON.stringify(tableHeader));

  const tableWidths = calcTableWidths(mainTableHeader());
  console.log('tablewidth', JSON.stringify(tableWidths));

  const tableRows = mainTableRows();
  console.log('tablerows', JSON.stringify(tableRows));

  // *** Report section: Main Table ***

  let mainTable;
  mainTable = [tableHeader, ...tableRows];

  // *** Full report doc def ***

  console.log('Document', JSON.stringify(mainTable));

  const workbook = new ExcelJS.Workbook();
  let sheet = workbook.addWorksheet(
    `${i18n.t('reportName.' + settings.reportName)}`
  );
  sheet.addRows(mainTable);

  sheet.columns.forEach((column, colIdx) => {
    let dataMax = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      let len = cell.value.toString().length;
      if (Object.keys(cell.value).includes('richText')) {
        len = cell.value.richText[0].text.toString().length;
        if (cell.value.richText[0].font && cell.value.richText[0].font.bold) {
          len *= 1.5;
        }
      }
      dataMax = len > dataMax ? len : dataMax;

      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if ([4, 5, 6, 7, 8, 9].includes(colIdx)) {
        cell.alignment = {
          ...cell.alignment,
          horizontal: 'center',
        };
      }
    });
    column.width = dataMax;
  });

  sheet.insertRows(1, mainHeader);
  sheet.mergeCells('B1:E1');
  sheet.getColumn(1).alignment = 'right';
  sheet.getColumn(1).width = 2;

  return workbook.xlsx.writeBuffer().then((buffer) => {
    return buffer.toString('base64');
  });
};

const calculateBreakRecordTotalReductions = (
  breakRecordTotalHours,
  mealBreakRecordTotalHours,
  coffeeBreakRecordTotalHours,
  paidCoffeeBreakMinutes
) => {
  const paidCoffeeBreakLengthValueInHours = paidCoffeeBreakMinutes
    ? Number(paidCoffeeBreakMinutes) / 60
    : 0;
  let totalReductions = 0;

  if (breakRecordTotalHours > 0) {
    totalReductions += breakRecordTotalHours;
  }

  if (mealBreakRecordTotalHours > 0) {
    totalReductions = mealBreakRecordTotalHours;
  }

  if (
    coffeeBreakRecordTotalHours &&
    coffeeBreakRecordTotalHours > paidCoffeeBreakLengthValueInHours
  ) {
    const coffeeBreakLengthValueWithPaidPortionReduced =
      coffeeBreakRecordTotalHours - paidCoffeeBreakLengthValueInHours;

    totalReductions += coffeeBreakLengthValueWithPaidPortionReduced;
  }
  return totalReductions;
};
